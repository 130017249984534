import _ from 'lodash'
import types from '../constants'
import { fetchProductPrice } from '../api'
import { upateProductPrice } from '../actions/checkin'
import { shouldUpateProductPrice } from '../modules/productPrice'

export default ({ getState, dispatch }) => {
  const debouncedDispatch = _.debounce(dispatch, 1000)
  return next => async action => {
    switch (action.type) {
      case types.UPDATE_PRODUCT_PRICE: {
        const serviceTxn = _.get(getState(), 'checkin.serviceTxn')
        if (shouldUpateProductPrice(serviceTxn)) {
          const productPriceData = await fetchProductPrice(
            serviceTxn.productId,
            serviceTxn,
          )
          return next({
            ...action,
            productPriceData,
          })
        }

        return next(action)
      }
      case types.UPDATE_CHECKIN_SERVICETXN_FIELD: {
        if (observedFields.some(key => key === action.keyPath)) {
          next(action)
          debouncedDispatch(upateProductPrice())
          return
        }
        break
      }
      default:
        break
    }

    return next(action)
  }
}

const observedFields = [
  'productId',
  'serviceInfo.inTime',
  'serviceInfo.guestPaxAdult',
  'serviceInfo.guestPaxChild',
  'serviceInfo.guestPaxInfant',
]
