import moment from "moment";
import { parseFieldToMomentIfNeeded } from "../reducers/parser";
import { removeUndefinedFromObject } from "../utils/url";

let store;
let jwt;

export const initAPI = (_store) => {
  store = _store;
  store.subscribe(stateListener);
};

export const stateListener = () => {
  jwt =
    store.getState().auth && store.getState().auth.jwt
      ? store.getState().auth.jwt
      : undefined;
};

const withAuthFetch = (func) => {
  return func(createAuthFetch());
};

const createAuthFetch = () => (url, options) => {
  if (!jwt) return Promise.reject("Authentication token is not found");

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json",
    },
    ...options,
  });
};

export const fetchServiceTxns = withAuthFetch(
  (authFetch) => (query) =>
    authFetch(
      `/api/serviceTxns?${new URLSearchParams(removeUndefinedFromObject(query))}`,
    )
      .then((data) => data.json())
      .then((serviceTxns) =>
        serviceTxns.map((s) => ({
          ...s,
          serviceDateTime: s.serviceDateTime
            ? moment(s.serviceDateTime)
            : undefined,
        })),
      ),
);

export const fetchReservations = withAuthFetch(
  (authFetch) => (query) =>
    authFetch(`/api/reservations?${new URLSearchParams(removeUndefinedFromObject(query))}`)
      .then((data) => data.json())
      .then((data) => parseFieldToMomentIfNeeded(data)),
);

export const fetchAirlines = withAuthFetch(
  (authFetch) => (query) =>
    authFetch(`/api/airlines?${new URLSearchParams(removeUndefinedFromObject(query))}`).then((data) =>
      data.json(),
    ),
);

export const fetchAirlineById = withAuthFetch(
  (authFetch) => (id) =>
    authFetch(`/api/airlines/${id}`).then((data) => data.json()),
);

export const fetchCardById = withAuthFetch(
  (authFetch) => (id) =>
    authFetch(`/api/cards/${id}`).then((data) => data.json()),
);

export const fetchProductPrice = withAuthFetch(
  (authFetch) => (id, serviceTxn) =>
    authFetch(`/api/products/${id}/price`, {
      method: "PUT",
      body: JSON.stringify(serviceTxn),
    }).then((data) => data.json()),
);
