import React from 'react'
import { connect } from 'react-redux'
import { defaultTo } from 'lodash'
import { escape } from '../../utils/regex'
import { fetchProducts, clearProduct } from '../../actions/remote_api'
import SearchSelect from '../select/SearchSelect'

class ProductSearchSelect extends React.Component {
  render() {
    return (
      <SearchSelect
        {...this.props}
        options={this.props.options}
        createQuery={defaultTo(this.props.createQuery, createQuery)}
        createLabel={createLabel}
        defaultValueQuery={defaultValueQuery}
        selectId="productOptions"
      />
    )
  }
}

export const createQuery = searchText => ({
  name_like: searchText ? escape(searchText.toLowerCase()) : undefined,
  sort: 'name',
  order: 'asc',
})

const defaultValueQuery = initialId => ({
  _id: initialId,
})

const createLabel = p => p.name

export default connect(
  null,
  {
    fetchOptions: fetchProducts,
    clearOptions: clearProduct,
  },
)(ProductSearchSelect)
