import { createCRUDActions, createURL } from './remote_api_utils'

const serviceActions = createCRUDActions('service', '/api/services')

export const fetchServices = serviceActions.list
export const fetchService = serviceActions.get
export const deleteService = serviceActions.delete
export const createService = serviceActions.create
export const updateService = serviceActions.update
export const clearService = serviceActions.clear

const serviceStationActions = createCRUDActions(
  'serviceStation',
  '/api/services/stations',
)

export const fetchServiceStations = serviceStationActions.list
export const fetchServiceStation = serviceStationActions.get
export const deleteServiceStation = serviceStationActions.delete
export const createServiceStation = serviceStationActions.create
export const updateServiceStation = serviceStationActions.update
export const clearServiceStation = serviceStationActions.clear

const airlineActions = createCRUDActions('airline', '/api/airlines')

export const fetchAirlines = airlineActions.list
export const fetchAirline = airlineActions.get
export const deleteAirline = airlineActions.delete
export const createAirline = airlineActions.create
export const updateAirline = airlineActions.update
export const clearAirline = airlineActions.clear

const agentActions = createCRUDActions('agent', '/api/agents')

export const fetchAgents = agentActions.list
export const fetchAgent = agentActions.get
export const deleteAgent = agentActions.delete
export const createAgent = agentActions.create
export const updateAgent = agentActions.update
export const clearAgent = agentActions.clear

const reservationActions = createCRUDActions('reservation', '/api/reservations')

export const fetchReservations = reservationActions.list
export const fetchReservation = reservationActions.get
export const deleteReservation = reservationActions.delete
export const createReservation = reservationActions.create
export const updateReservation = reservationActions.update
export const clearReservation = reservationActions.clear

const reservationCountActions = createCRUDActions(
  'reservationCount',
  '/api/reservations/count',
)

export const fetchReservationCounts = reservationCountActions.list
export const clearReservationCounts = reservationCountActions.clear

const dailyTimeBoundaryActions = createCRUDActions(
  'dailyTimeBoundary',
  '/api/reservations/dailyTimeBoundary',
)

export const fetchDailyTimeBoundary = dailyTimeBoundaryActions.list

const cardActions = createCRUDActions('card', '/api/cards')

export const fetchCards = cardActions.list
export const fetchCard = cardActions.get
export const deleteCard = cardActions.delete
export const createCard = cardActions.create
export const updateCard = cardActions.update
export const clearCard = cardActions.clear

const serviceTxnActions = createCRUDActions('serviceTxn', '/api/serviceTxns')

export const fetchServiceTxns = serviceTxnActions.list
export const fetchServiceTxn = serviceTxnActions.get
export const deleteServiceTxn = serviceTxnActions.delete
export const createServiceTxn = serviceTxnActions.create
export const updateServiceTxn = serviceTxnActions.update
export const clearServiceTxn = serviceTxnActions.clear
export { extendServiceTxnDuration } from './serviceTxn'
export const cancelServiceTxn = (serviceTxn, { onResponse, fetchId }) => ({
  type: 'CANCEL_SERVICE_TXN',
  meta: {
    fetch_id: fetchId || 'serviceTxn',
    type: 'update',
  },
  $payload: {
    url: `/api/serviceTxns/${serviceTxn._id}/cancel`,
    options: {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify(serviceTxn),
    },
    onResponse,
  },
})

// MARK: - use /paxCount instead

export const fetchPaxCountByStation = stationId => ({
  type: `FETCH_PAX_COUNT_BY_STATION`,
  meta: { fetch_id: 'activePaxCount', type: 'single' },
  $payload: {
    url: createURL('/api/serviceTxns/paxCount', {
      checkedout: false,
      operationDaily: true,
      stationId,
    }),
  },
})

const checkedoutServiceTxnActions = createCRUDActions(
  'checkedoutServiceTxn',
  '/api/serviceTxns',
)

export const fetchCheckedoutServiceTxns = checkedoutServiceTxnActions.list
export const fetchCheckedoutServiceTxn = checkedoutServiceTxnActions.get
export const deleteCheckedoutServiceTxn = checkedoutServiceTxnActions.delete
export const createCheckedoutServiceTxn = checkedoutServiceTxnActions.create
export const updateCheckedoutServiceTxn = checkedoutServiceTxnActions.update
export const clearCheckedoutServiceTxn = checkedoutServiceTxnActions.clear

const bookingSummaryActions = createCRUDActions(
  'bookingSummary',
  '/api/bookingSummaries',
)

export const fetchBookingSummaries = bookingSummaryActions.list
export const fetchBookingSummary = bookingSummaryActions.get
export const deleteBookingSummary = bookingSummaryActions.delete
export const createBookingSummary = bookingSummaryActions.create
export const updateBookingSummary = bookingSummaryActions.update
export const clearBookingSummary = bookingSummaryActions.clear

const workSummaryActions = createCRUDActions(
  'workSummary',
  '/api/workSummaries',
)

export const fetchWorkSummaries = workSummaryActions.list
export const fetchWorkSummary = workSummaryActions.get
export const deleteWorkSummary = workSummaryActions.delete
export const createWorkSummary = workSummaryActions.create
export const updateWorkSummary = workSummaryActions.update
export const clearWorkSummary = workSummaryActions.clear

const staffActions = createCRUDActions('staff', '/api/staffs')

export const fetchStaffs = staffActions.list
export const fetchStaff = staffActions.get
export const deleteStaff = staffActions.delete
export const createStaff = staffActions.create
export const updateStaff = staffActions.update
export const clearStaff = staffActions.clear

const defaultsActions = createCRUDActions('defaults', '/api/defaults')

export const fetchDefaults = defaultsActions.list

const recentlyServiceTxnActions = createCRUDActions(
  'recentlyServiceTxn',
  '/api/serviceTxns',
)

export const fetchRecentlyServiceTxns = ({ serviceId }) =>
  recentlyServiceTxnActions.list({
    recentlyCheckin: 60, // mins
    limit: 10,
    sort: 'serviceInfo.inTime',
    order: 'desc',
    'service._id': serviceId,
    statusConfirmAndGreater: true,
  })

const stationActions = createCRUDActions('station', '/api/stations')

export const fetchStations = stationActions.list
export const fetchStation = stationActions.get
export const deleteStation = stationActions.delete
export const createStation = stationActions.create
export const updateStation = stationActions.update
export const clearStation = stationActions.clear

const itemActions = createCRUDActions('item', '/api/items')

export const fetchItems = itemActions.list
export const fetchItem = itemActions.get
export const deleteItem = itemActions.delete
export const createItem = itemActions.create
export const updateItem = itemActions.update
export const clearItem = itemActions.clear

const productActions = createCRUDActions('product', '/api/products')

export const fetchProducts = productActions.list
export const fetchProduct = productActions.get
export const deleteProduct = productActions.delete
export const createProduct = productActions.create
export const updateProduct = productActions.update
export const clearProduct = productActions.clear
