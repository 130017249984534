import React from 'react'
import { Label } from 'react-bootstrap';
import moment from 'moment'
import { I18n } from 'react-redux-i18n';

const formatDateTime = (dateTime, dateTimeFormat) => moment(dateTime).format(dateTimeFormat)

export default ({ dailyTimeBoundary }) => {
  if (!dailyTimeBoundary)
    return null

  const dateTimeFormat = I18n.t('date.shortDateTimeFormat')
  const startDateTime = formatDateTime(dailyTimeBoundary.start, dateTimeFormat)
  const endDateTime = formatDateTime(dailyTimeBoundary.end, dateTimeFormat)

  return (
    <Label>
      <span>
        {startDateTime} - {endDateTime}
      </span>
    </Label>
  )
}