import _ from 'lodash';

let reduxStore = null;
let cachedAuthState = null;

const handleChange = () => {
	cachedAuthState = reduxStore.getState().auth
}

export const initAuthService = (store) => {
	reduxStore = store;
	handleChange();
	reduxStore.subscribe(handleChange);
}

export const checkIfAnyGrantedByGivenAuth = (authState, roles) =>
	(authState.isLoggedIn && _.intersection(authState.user.roles, roles).length > 0)


export const ifAnyGranted = (roles) => checkIfAnyGrantedByGivenAuth(cachedAuthState, roles);
