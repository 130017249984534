import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import StarRatingComponent from 'react-star-rating-component'
import styled from 'styled-components'
export default class FeedbackModal extends React.Component {
  state = {
    customerSatisfactionRate: undefined,
    noteType: null,
    note: ''
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.serviceTxn && nextProps.serviceTxn.serviceInfo) {
      const {
        customerSatisfactionRate,
        noteType,
        note,
      } = nextProps.serviceTxn.serviceInfo
      this.setState({
        customerSatisfactionRate,
        noteType,
        note,
      })
    }
  }

  handleRatingChange = customerSatisfactionRate => {
    this.setState(state => ({
      ...state,
      customerSatisfactionRate,
    }))
  }

  handleRatingClear = () => {
    this.handleRatingChange(undefined)
  }

  handleNoteTypeChange = noteType => {
    this.setState({ ...this.state, noteType })
  }

  handleNoteChange = note => {
    this.setState({ ...this.state, note })
  }

  handleFeedback = () => {
    const { serviceTxn, onFeedback } = this.props
    const { customerSatisfactionRate, noteType, note } = this.state
    onFeedback(serviceTxn, { customerSatisfactionRate, noteType, note })
  }

  render() {
    const { isShow, onHide, serviceTxn } = this.props
    const label = I18n.t('stationReportScene.feedbackModal')
    return (
      <Modal show={isShow} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title><b>{label.title.toUpperCase()}</b></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {renderModalBody(
            serviceTxn,
            label,
            this.state.customerSatisfactionRate,
            this.handleRatingChange,
            this.handleRatingClear,
            this.state.noteType,
            this.handleNoteTypeChange,
            this.state.note,
            this.handleNoteChange,
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>{I18n.t('action.cancel')}</Button>
          <Button onClick={this.handleFeedback} bsStyle="primary">{I18n.t('action.save')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const renderModalBody = (
  serviceTxn,
  label,
  customerSatisfactionRate,
  onRatingChange,
  onRatingClear,
  noteType,
  onNoteTypeChange,
  note,
  onNoteChange
) => {
  const handleNoteChange = e => onNoteChange(e.target.value)
  if (serviceTxn) {
    return (
      <div>
        <dl className="dl-horizontal" style={{ fontSize: 18 }}>
          <dt>{label.customer}</dt>
          <dd>{serviceTxn.reservation.mainPax.fullName}</dd>
          <dt>{label.customerSatisfactionRate}</dt>
          <dd>
            <div>
              <StarRatingComponent
                className="pull-left kiosk-star"
                name="customerSatisfactionRate"
                value={customerSatisfactionRate}
                onStarClick={onRatingChange}
                starCount={3} />

              <ClearBtnContainer>
                <span onClick={onRatingClear} className="glyphicon glyphicon-remove"></span>
              </ClearBtnContainer>
            </div>
          </dd>
          <dt>{label.noteType}</dt>
          <dd>
            <ToggleButtonGroup type="radio" name="noteType"
              defaultValue="comment"
              value={noteType} onChange={onNoteTypeChange}>
              <ToggleButton value="comment" className="btn-primary">
                {label.noteTypeComment}
              </ToggleButton>
              <ToggleButton value="complain" className="btn-primary">
                {label.noteTypeComplain}
              </ToggleButton>
            </ToggleButtonGroup>
          </dd>
        </dl>
        <textarea value={note} onChange={handleNoteChange}
          type="text" name="note" className="form-control" rows="8" />
      </div>
    )
  }
  return null
}

const ClearBtnContainer = styled.div.attrs({
  className: "pull-left"
}) `
  margin-left: 10px;
  margin-top: 3px;
  color: #d9534f;

  :hover{
    color: #c9302c;
    cursor: pointer;
  }
`