const authHeaderMiddleware = store => next => action => {
  if (action.$payload && store.getState().auth.isLoggedIn) {
    if (!action.$payload.options)
      action.$payload.options = { headers: {} }
    if (!action.$payload.options.headers)
      action.$payload.options.headers = {}
    action.$payload.options.headers.Authorization = `Bearer ${store.getState().auth.jwt}`;
  }
  return next(action);
}

export default authHeaderMiddleware;