import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  ModalBody,
  Button
} from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'

export default ({
  isShow,
  onHide,
  serviceTxn,
  on15MinExtendDurationClick,
  on30MinExtendDurationClick,
  on45MinExtendDurationClick,
  on60MinExtendDurationClick,
}) => {
  const timeFormat = I18n.t('date.timeFormat')
  return (
    <Modal
      show={isShow}
      onHide={onHide}>
      <ModalHeader closeButton>
        <ModalTitle>Extend Duration</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Dl className="dl-horizontal">
          <dt>Passenger Name</dt>
          <dd>{serviceTxn && serviceTxn.reservation.mainPax.fullName}</dd>
          <dt>Service</dt>
          <dd>{serviceTxn && serviceTxn.service && `${serviceTxn.service.displayName}`}</dd>
          <dt>In Time</dt>
          <dd>{serviceTxn && serviceTxn.serviceInfo && serviceTxn.serviceInfo.inTime.format(timeFormat)}</dd>
          <dt>Extended Hours</dt>
          <dd>{serviceTxn && serviceTxn.serviceInfo && serviceTxn.serviceInfo.extraDuration ? serviceTxn.serviceInfo.extraDuration : 0}</dd>
          <dt>Extended Time</dt>
          <dd><u>{serviceTxn && serviceTxn.serviceInfo && serviceTxn.serviceInfo.issuedOutTime.format(timeFormat)}</u></dd>
        </Dl>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-primary"
          bsSize="lg"
          onClick={on15MinExtendDurationClick}>
          <span className="glyphicon glyphicon-plus" /> 15
        </Button>
        <Button
          className="btn btn-primary"
          bsSize="lg"
          onClick={on30MinExtendDurationClick}>
          <span className="glyphicon glyphicon-plus" /> 30
        </Button>
        <Button
          className="btn btn-primary"
          bsSize="lg"
          onClick={on45MinExtendDurationClick}>
          <span className="glyphicon glyphicon-plus" /> 45
        </Button>
        <Button
          className="btn btn-primary"
          bsSize="lg"
          onClick={on60MinExtendDurationClick}>
          <span className="glyphicon glyphicon-plus" /> 60
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const Dl = styled.dl`
font-size: 18px;
`