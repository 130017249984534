import React from 'react'
import { connect } from 'react-redux'
import { fetchDailyTimeBoundary } from '../../actions/remote_api'

export default (Component) => {
  return defaultsConnect(
    class DailyTimeBoundaryWrapper extends React.Component {
      componentDidMount() {
        this.props.fetchDailyTimeBoundary()
      }

      render() {
        return (
          <Component
            {...this.props}
            dailyTimeBoundary={this.props.dailyTimeBoundary} />
        )
      }
    }
  )
}

const defaultsConnect = connect(
  ({ fetch: { dailyTimeBoundary } }) => ({
    dailyTimeBoundary: dailyTimeBoundary && dailyTimeBoundary.list ?
      dailyTimeBoundary.list :
      undefined
  }),
  { fetchDailyTimeBoundary }
)