import React from 'react'
import ReservationTableContainer from '../../components/ReservationTable'
import ReassignJobAssignmentModalContainer from './ReassignJobAssignmentModalContainer'
import AcceptJobAssignmentConfirmModal from './AcceptJobAssignmentConfirmModal'
import { connect } from 'react-redux'
import { fetchReservations, clearReservation, updateReservation } from '../../actions/remote_api'
import { toastr } from 'react-redux-toastr'

class AssignmentOnQueueTableContainer extends React.Component {

  initialState = () => ({
    reassignJobAssignmentModal: {
      isShow: false,
      currentAssignmentInfo: {},
    },
    acceptJobAssignmentModal: {
      isShow: false,
      reservation: undefined,
      jobAssignment: undefined,
    }
  })

  state = this.initialState()

  fetchInstances = (params) => {
    toastr.info('Loading')
    this.props.fetchReservations({
      ...params,
      onQueue: true,
      daily: true,
      createByKiosk: false,
      sort: 'serviceDateTime',
      order: 'asc',
    })
  }

  clearInstances = () => {
    this.props.clearReservation('list')
  }

  handleAssignClick = (reservation, jobAssignment) => {
    this.handleClearJobAssignmentModalData(() => {
      this.setState({
        reassignJobAssignmentModal: {
          isShow: true,
          currentAssignmentInfo: {
            reservation, jobAssignment
          },
        }
      })
    })
  }

  handleAcceptClick = (reservation, jobAssignment) => {
    this.handleClearJobAssignmentModalData(() => {
      this.setState({
        ...this.state,
        acceptJobAssignmentModal: {
          isShow: true,
          reservation,
          jobAssignment
        }
      })
    })
  }

  handleJobAssignmentModalClose = () => {
    this.setState({
      ...this.state,
      reassignJobAssignmentModal: {
        ...this.state.reassignJobAssignmentModal,
        isShow: false,
      }
    })
  }

  handleClearJobAssignmentModalData = (callback) => {
    this.setState(this.initialState(), callback)
  }

  handleReassignJobAssignmentModalSubmit = ({
    reservation,
    jobAssignment,
    targetStaff,
  }) => {
    const nextReservation = {
      ...reservation,
      jobAssignments: reservation.jobAssignments.map(j =>
        j._id !== jobAssignment._id ? j : {
          ...j,
          staffId: targetStaff._id,
          staff: targetStaff,
        }
      ),
    }
    this.props.updateReservation(nextReservation)
    toastr.success("Reassigned")
    this.setState({
      ...this.state,
      reassignJobAssignmentModal: {
        ...this.state.reassignJobAssignmentModal,
        isShow: false,
      }
    })
  }

  handleAcceptJobAssignmentModalSubmit = () => {
    const { reservation, jobAssignment } = this.state.acceptJobAssignmentModal
    if (reservation && jobAssignment) {
      const nextReservation = {
        ...reservation,
        jobAssignments: reservation.jobAssignments.map(j =>
          j._id !== jobAssignment._id ? j : {
            ...j,
            status: 'accepted',
          }
        ),
      }
      this.props.updateReservation(nextReservation)
      toastr.success("Job accepted")
      this.handleAcceptJobAssignmentModalClose()
    }
  }

  handleAcceptJobAssignmentModalClose = () => {
    this.setState({
      ...this.state,
      acceptJobAssignmentModal: {
        ...this.state.acceptJobAssignmentModal,
        isShow: false
      }
    })
  }

  render() {
    return (
      <div>
        <ReservationTableContainer
          fetchInstances={this.fetchInstances}
          clearInstances={this.clearInstances}
          isCustomerSatisfactionShow={false}
          isAssignable={true}
          onAssignClick={this.handleAssignClick}
          onAcceptClick={this.handleAcceptClick}
          {...this.props} />
        <ReassignJobAssignmentModalContainer
          isShow={this.state.reassignJobAssignmentModal.isShow}
          currentAssignmentInfo={this.state.reassignJobAssignmentModal.currentAssignmentInfo}
          onClose={this.handleJobAssignmentModalClose}
          onSubmit={this.handleReassignJobAssignmentModalSubmit} />

        <AcceptJobAssignmentConfirmModal
          isShow={this.state.acceptJobAssignmentModal.isShow}
          jobAssignment={this.state.acceptJobAssignmentModal.jobAssignment}
          onHide={this.handleAcceptJobAssignmentModalClose}
          onSubmit={this.handleAcceptJobAssignmentModalSubmit} />
      </div>
    )
  }
}

export default connect(
  ({ fetch }) => ({
    instances: fetch.reservation ? fetch.reservation.list : undefined,
    totalCount: fetch.reservation ? fetch.reservation.totalCount : undefined,
  }),
  { fetchReservations, clearReservation, updateReservation }
)(AssignmentOnQueueTableContainer)
