import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Well,
} from 'react-bootstrap'
import CardPrivilegeSelectItem from './CardPrivilegeSelectItem'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

export default ({
  isShow,
  onHide,
  card,
  classToServiceMapping,
  onCardPrivilegeSelect,
}) => {
  return (
    <Modal
      show={isShow}
      onHide={onHide}
      backdrop='static'>
      <ModalHeader closeButton>
        <h1>CHOOSE CARD PRIVILEGE</h1>
      </ModalHeader>
      <ModalBody>
        {renderPrivilegeMenu(
          card,
          classToServiceMapping,
          onCardPrivilegeSelect
        )}
      </ModalBody>
    </Modal>
  )
}

const renderPrivilegeMenu = (
  card,
  classToServiceMapping,
  onCardPrivilegeSelect
) => {
  if (!card || !classToServiceMapping)
    return <div>No Privileges for this card</div>

  return (
    <Row>
      {renderEmptyStateIfNeeded(classToServiceMapping)}
      {classToServiceMapping.map(classToServiceMapping =>
        <Col
          md={6}
          key={classToServiceMapping._id}>
          <CardPrivilegeSelectItem
            classToServiceMapping={classToServiceMapping}
            onClick={() =>
              onCardPrivilegeSelect(card, classToServiceMapping)
            } />
        </Col>
      )}
    </Row>
  )
}

const renderEmptyStateIfNeeded = (classToServiceMapping) => {
  if (isEmpty(classToServiceMapping)) {
    return (
      <WellWithMargin>
        <h3>No privileges for this type of card and service</h3>
      </WellWithMargin>
    )
  }

  return null
}

const WellWithMargin = styled(Well)`
  margin-left: 16px;
  margin-right: 16px
`