import React from 'react'
import MarkFinalResultModal from './MarkFinalResultModal'
import { startScan, stopScan } from '../../utils/codeScan'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import {
  completeServiceTxn,
  noShowServiceTxn,
  missedCaseServiceTxn,
} from '../../actions/serviceTxn'
import {
  clearServiceTxn,
} from '../../actions/remote_api'

const fetchId = 'markFinalServiceTxn'

class MarkFinalResultModalContainer extends React.Component {

  state = {
    recentMarkFinalServiceTxns: []
  }

  handleStartScan = () => {
    startScan(this.handleScanSucceed, this.handleScanCanceled)
  }

  handleStopScan = () => {
    stopScan();
  }

  handleScanSucceed = code => {
    // const codeInfo = parseBarcode(code)
    // this.props.useCodeScanInfo(
    //   codeInfo,
    //   info().serviceId,
    //   this.handleScanError
    // )
    if (isEmpty(code))
      return
    let markFinalStateAction
    switch (this.props.type) {
      case 'completed':
        markFinalStateAction = this.props.completeServiceTxn
        break;
      case 'noShow':
        markFinalStateAction = this.props.noShowServiceTxn
        break;
      case 'missedCase':
        markFinalStateAction = this.props.missedCaseServiceTxn
        break;
      default:
        break;
    }

    if (markFinalStateAction)
      markFinalStateAction(
        code,
        {
          fetchId,
          onResponse: this.createMarkFinalStateResponseHandler(this.props.type)
        },
      )
  }

  createMarkFinalStateResponseHandler = type => async response => {
    if (!response.ok) {
      toastr.error('Error', await response.text())
    }
  }

  handleScanCanceled = () => {
    this.props.onHide()
  }

  handleScanError = error => {
    var errorMessage = ""

    switch (error.code) {
      case 'airlineCode.notFound':
        errorMessage = "Airline code is not found in this information";
        break;
      case 'airline.notFound':
        errorMessage = `Airline is not found for given ${error.airlineCode}`;
        break;
      case 'context.notSupported':
        errorMessage = "Code information's context is not supported";
        break;
      case 'confirmation.notFound':
        errorMessage = "Confirmation Number is not found in information";
        break;
      case 'reservation.notFound':
        errorMessage = `Reservation is not found for given ${error.confirmationNumber}`;
        break;

      default:
        errorMessage = "Unknown Error!";
        break;
    }

    toastr.error('Error', errorMessage)
  }

  clearState() {
    this.setState(state => ({
      recentMarkFinalServiceTxns: []
    }))
    this.props.clearServiceTxn('single', { fetchId })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isShow && !nextProps.isShow) {
      this.clearState()
      this.handleStopScan()
    } else if (!this.props.isShow && nextProps.isShow) {
      this.handleStartScan()
    }

    if (
      (!this.props.recentMarkFinalServiceTxn && nextProps.recentMarkFinalServiceTxn) ||
      (
        this.props.recentMarkFinalServiceTxn &&
        nextProps.recentMarkFinalServiceTxn &&
        this.props.recentMarkFinalServiceTxn._id !== nextProps.recentMarkFinalServiceTxn._id
      )
    ) {
      this.addRecentMarkFinalServiceTxn(nextProps.recentMarkFinalServiceTxn)
    }
  }

  addRecentMarkFinalServiceTxn = recentMarkFinalServiceTxn => {
    this.setState(state => ({
      recentMarkFinalServiceTxns: [
        ...state.recentMarkFinalServiceTxns,
        recentMarkFinalServiceTxn,
      ]
    }))
  }

  render() {
    const { isShow, onHide, type } = this.props
    return (
      <MarkFinalResultModal
        recentMarkFinalServiceTxns={this.state.recentMarkFinalServiceTxns}
        isShow={isShow}
        onHide={onHide}
        type={type} />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    recentMarkFinalServiceTxn: fetch[fetchId] && fetch[fetchId].single ?
      fetch[fetchId].single :
      undefined
  }),
  {
    completeServiceTxn,
    noShowServiceTxn,
    missedCaseServiceTxn,
    clearServiceTxn,
  }
)(MarkFinalResultModalContainer)