import React from 'react'
import CheckedoutServiceTxnTableContainer from './CheckedoutServiceTxnTableContainer'

export default class CheckedoutServiceTxnPanelContainer extends React.Component {

  render() {
    return (
      <div className="col-md-12">
          <hr />
          <CheckedoutServiceTxnTableContainer {...this.props} />
      </div>
    )
  }
}
