import React from 'react'
import { ListGroupItem } from 'react-bootstrap'

export default ({ serviceTxn, onClick }) => (
  <ListGroupItem
    key={serviceTxn._id}
    header={serviceTxn.reservation.mainPax.fullName}
    onClick={onClick}>
    <span>{serviceTxn.reservation.confirmationNumber}</span>
    <span className="pull-right">
      {serviceTxn.serviceInfo.inTime.fromNow()}
    </span>
  </ListGroupItem>
)