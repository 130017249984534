import React from 'react'
import { I18n } from 'react-redux-i18n'
import CheckInFormInfo from './CheckInFormInfo'
import moment from 'moment'

export default class CheckInFormInfoContainer extends React.Component {
  state = {
    currentTime: moment(),
  }

  componentDidMount() {
    // this.interval = setInterval(() => {
    //   this.setState((state) => ({
    //     currentTime: moment(),
    //   }))
    // }, 1000)
  }

  componentWillUnmount() {
    if (this.interval)
      clearInterval(this.interval)
  }

  getTimeFormat(timeMoment) {
    const timeFormat = I18n.t('date.timeFormat')
    return timeMoment ? timeMoment.format(timeFormat) : undefined
  }

  render() {
    // const currentTime = this.getTimeFormat(this.state.currentTime)
    const inTime = this.getTimeFormat(this.props.inTime)
    const limitTime = this.getTimeFormat(this.props.limitTime)
    const outTime = this.getTimeFormat(this.props.outTime)

    return (
      <CheckInFormInfo
        inTime={inTime}
        limitTime={limitTime}
        outTime={outTime} />
    )
  }
}