import React from 'react'
import AssignmentMenuItem from './AssignmentMenuItem'

export default ({ pendingNotificationCount, onQueueNotificationCount }) => {
  return (
    <div className="summaryReport-container">
      <AssignmentMenuItem
        to="/assignment/staff"
        title="STAFF" />
      <AssignmentMenuItem
        to="/assignment/pending"
        title="PENDING"
        notificationCount={pendingNotificationCount} />
      <AssignmentMenuItem
        to="/assignment/on-queue"
        title="ON QUEUE"
        notificationCount={onQueueNotificationCount} />
    </div>
  )
}