import React from 'react'
import AppBar from '../../components/AppBar'
import CustomerSummaryPanelContainer from './CustomerSummaryPanelContainer'
import { info } from '../../storage'

export default class CustomerSummaryScene extends React.Component {
  handleBackScene = () => {
    if (this.props.history)
      this.props.history.goBack()
  }

  render() {
    const { stationName, serviceDisplayName } = info()
    return (
      <div className="service">
        <AppBar
          header="Summary Report"
          subHeader={`Booking Summary (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup />
        <div className="kiosk-container">
          <div className="kiosk-panel summary-report">
            <div className="col-md-12 col-sm-12 col-xs-12 kiosk-fullheight ">
              <CustomerSummaryPanelContainer
                onBack={this.handleBackScene} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}