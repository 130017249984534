import React from 'react'
import { connect } from 'react-redux'
import { extendServiceTxnDuration } from '../../actions/remote_api'
import ExtendDurationModal from './ExtendDurationModal'
import { toastr } from 'react-redux-toastr'

class ExtendDurationModalContainer extends React.Component {

  handle15MinExtendDurationClick = () => {
    this.handleExtendDurationClick({ minutes: 15 })
  }

  handle30MinExtendDurationClick = () => {
    this.handleExtendDurationClick({ minutes: 30 })
  }

  handle45MinExtendDurationClick = () => {
    this.handleExtendDurationClick({ minutes: 45 })
  }

  handle60MinExtendDurationClick = () => {
    this.handleExtendDurationClick({ minutes: 60 })
  }

  handleExtendDurationClick = (duration) => {
    this.props.extendServiceTxnDuration(
      this.props.serviceTxn,
      duration,
      this.onExtendDurationResponse
    )    
  }

  onExtendDurationResponse = response => {
    if (response.ok) {
      toastr.success('Extend duration completed')
      this.props.onHide()
    } else {
      toastr.error('Extend duration failed', 'please try again')
    }
  }

  render() {
    return (
      <ExtendDurationModal
        {...this.props}
        on15MinExtendDurationClick={this.handle15MinExtendDurationClick}
        on30MinExtendDurationClick={this.handle30MinExtendDurationClick}
        on45MinExtendDurationClick={this.handle45MinExtendDurationClick}
        on60MinExtendDurationClick={this.handle60MinExtendDurationClick} />
    )
  }
}

export default connect(
  null,
  { extendServiceTxnDuration }
)(ExtendDurationModalContainer)