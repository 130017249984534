import React from 'react'
import AppBar from '../../components/AppBar'
import storage from '../../storage'
import WorkSummarySplitView from './WorkSummarySplitView'

export default class WorkSummaryScene extends React.Component {
  handleBackScene = () => {
    if (this.props.history)
      this.props.history.goBack()
  }

  render() {
    const { stationName, serviceDisplayName } = storage.info()
    return (
      <div className="service">
        <AppBar
          header="Summary Report"
          subHeader={`Work Summary (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup />

        <WorkSummarySplitView 
          onBack={this.handleBackScene} />
      </div>
    )
  }
}