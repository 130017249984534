import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import StarRatingComponent from 'react-star-rating-component'

export default ({ isShow, onHide, serviceTxn }) => {
    const label = I18n.t('stationReportScene.feedbackModal')
    return (
        <Modal show={isShow} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title><b>Complain Message</b></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {renderModalBody(
                    serviceTxn,
                    label
                )}
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onHide}>{I18n.t('action.cancel')}</Button>
            </Modal.Footer>
        </Modal>
    )
}

const renderModalBody = (
    serviceTxn,
    label
) => {
    if (serviceTxn) {
        return (
            <div>
                <dl className="dl-horizontal" style={{ fontSize: 18 }}>
                    <dt>{label.customerSatisfactionRate}</dt>
                    <dd>
                        <div>
                            <StarRatingComponent
                                className="pull-left kiosk-star"
                                name="customerSatisfactionRate"
                                value={serviceTxn.serviceInfo.customerSatisfactionRate}
                                starCount={3} />
                        </div>
                    </dd>

                </dl>
                <textarea disabled value={serviceTxn.serviceInfo.note}
                    type="text" name="note" className="form-control" rows="8" />
            </div>
        )
    }
    return null
}
