import { withStateHandlers, compose } from 'recompose'

export const withConfirmModal = compose(
  withStateHandlers(() => ({ isShow: false }), {
    onHide: () => () => ({ isShow: false }),
    showModal: () => () => ({ isShow: true }),
  }),
)

export const ConfirmModal = withConfirmModal(
  ({ isShow, showModal, onHide, children }) =>
    children({ isShow, onHide, showModal }),
)
