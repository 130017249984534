import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { cancelServiceTxn } from '../actions/remote_api'

const withServiceTxnCancel = compose(
  connect(
    null,
    { cancelServiceTxn },
  ),
  withHandlers({
    cancelServiceTxn: ({
      cancelServiceTxn,
      serviceTxn,
    }) => onResponse => () => {
      cancelServiceTxn(serviceTxn, { onResponse })
    },
  }),
)

export const ServiceTxnCancelAction = withServiceTxnCancel(
  ({ cancelServiceTxn, children }) => children(cancelServiceTxn),
)
