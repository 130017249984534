import _ from 'lodash'
import moment from 'moment'

// eslint-disable-next-line
const max_standard_iata = 158;
/*eslint no-useless-escape: "off"*/
const iataFormat = {
	// Mandatory
	formatCode: { length: 1, offset: 1, content: "S|M", explanation: "Format Code" },
	numberOfSegments: { length: 1, offset: 2, content: "[1-4]", explanation: "Number of Legs Encoded" },
	passengerName: { length: 20, offset: 22, content: "", explanation: "Passenger Name" },
	electronicTicketIndicator: { length: 1, offset: 23, content: "E", explanation: "Electronic Ticket Indicator" },
	operatingCarrierPnrCode: { length: 7, offset: 30, content: "", explanation: "Operating Carrier PNR Code" },
	fromCityAirportCode: { length: 3, offset: 33, content: "", explanation: "From City Airport Code" },
	toCityAirportCode: { length: 3, offset: 36, content: "", explanation: "To City Airport Code" },
	operatingCarrierDesignator: { length: 3, offset: 39, content: "", explanation: "Operating carrier Designator" },
	flightNumber: { length: 5, offset: 44, content: "", explanation: "Flight Number" },
	dateOfFlight: { length: 3, offset: 47, content: "[0-9]{3}", explanation: "Date of Flight (Julian Date)" },
	compartmentCode: { length: 1, offset: 48, content: "[A-Z]", explanation: "Compartment Code" },
	seatNumber: { length: 4, offset: 52, content: "", explanation: "Seat Number" },
	checkInSequenceNumber: { length: 5, offset: 57, content: "", explanation: "Check-in Sequence Number" },
	passengerStatus: { length: 1, offset: 58, content: "[0-9A-Z]", explanation: "Passenger Status" },
	conditionalsSize: { length: 2, offset: 60, content: "[0-F]{2}", explanation: "Field size of variable field (Conditional + Airline item 4)" },

	// Conditional                                                                                                                   
	beginningOfVersionNumber: { length: 1, offset: 61, content: "GREATER_THAN", explanation: "Beginning of version number" },
	versionNumber: { length: 1, offset: 62, content: "[1-5]", explanation: "Version number" },
	uniqueConditionalsSize: { length: 2, offset: 64, content: "[0-F]{2}", explanation: "Field size of following structured message - unique" },
	passengerDescription: { length: 1, offset: 65, content: "[0-9A-Z\s]", explanation: "Passenger Description" },
	sourceOfCheckIn: { length: 1, offset: 66, content: "[WKRMOTV\s]", explanation: "Source of check-in" },
	sourceOfBoardingPassIssuance: { length: 1, offset: 67, content: "[WKXRMOTV\s]", explanation: "Source of Boarding Pass Issuance" },
	dateOfPassIssuance: { length: 4, offset: 71, content: "[0-9]{4}", explanation: "Date of Issue of Boarding Pass (Julian Date)" },
	documentType: { length: 1, offset: 72, content: "B|I", explanation: "Document Type" },
	airlineDesignatorOfIssuer: { length: 3, offset: 75, content: "", explanation: "Airline Designator of boarding pass issuer" },
	baggageTagLicensePlate: { length: 13, offset: 88, content: "", explanation: "Baggage Tag License Plate Number (s)" },
	firstBaggageTagLicensePlate: { length: 13, offset: 101, content: "", explanation: "1st Non-Consecutive Baggage Tag License Plate Number" },
	secondBaggageTagLicensePlate: { length: 13, offset: 114, content: "", explanation: "2nd Non-Consecutive Baggage Tag License Plate Number" },
	repeatedConditionalsSize: { length: 2, offset: 116, content: "[0-F]{2}", explanation: "Field size of following structured message - repeated" },
	airlineNumericCode: { length: 3, offset: 119, content: "[0-9]{3}", explanation: "Airline Numeric Code" },
	serialNumber: { length: 10, offset: 129, content: "", explanation: "Document Form/Serial Number" },
	selecteeIndicator: { length: 1, offset: 130, content: "[\s0-1]", explanation: "Selectee Indicator" },
	internationalDocumentVerification: { length: 1, offset: 131, content: "[\s0-2]", explanation: "International Document Verification" },
	marketingCarrierDesignator: { length: 3, offset: 134, content: "", explanation: "Marketing Carrier Designator" },
	frequentFlyerAirlineDesignator: { length: 3, offset: 137, content: "", explanation: "Frequent Flyer Airline Designator" },
	frequentFlyerNumber: { length: 16, offset: 153, content: "", explanation: "Frequent Flyer Number" },
	idAdIndicator: { length: 1, offset: 154, content: "", explanation: "ID/AD Indicator" },
	freeBaggageAllowance: { length: 3, offset: 157, content: "", explanation: "Free Baggage Allowance" },
	fastTrack: { length: 1, offset: 158, content: "[YN\s]", explanation: "Fast Track" },
};

/*
--sourceOfCheckIn--
	WEB            ("W", "Web"),
    AIRPORT_KIOSK  ("K", "Airport Kiosk"),
    OFF_SITE_KIOSK ("R", "Remote or Off Site Kiosk"),
    MOBILE_DEVICE  ("M", "Mobile Device"),
    AIRPORT_AGENT  ("O", "Airport Agent"),
    TOWN_AGENT     ("T", "Town Agent"),
    THIRD_PARTY    ("V", "Third Party Vendor"),
*/

/*
--Compartment Code--
    SUPERSONIC("Supersonic", "R"),
    FIRST_CLASS_PREMIUM("First Class Premium", "P"),
    FIRST_CLASS("First Class", "F"),
    FIRST_CLASS_DISCOUNTED("First Class Discounted", "A"),

    // Business
    BUSINESS_CLASS_PREMIUM("Business Class Premium", "J"),
    BUSINESS_CLASS("Business Class", "C"),
    BUSINESS_CLASS_DISCOUNTED("Business Class Discounted", "D", "I", "Z"),

    // Economy
    ECONOMY_PREMIUM("Economy/Coach Premium", "W"),
    ECONOMY("Economy/Coach", "S", "Y"),
    ECONOMY_DISCOUNTED("Economy/Coach Discounted", "B", "H", "K", "L", "M", "N", "Q", "T", "X"),

*/

function getIataElement(input, element) {
	if (element.offset <= input.length) {
		let tmp = input.substring(element.offset - element.length, element.offset);
		return tmp.trim();
	}
	return null
}

//================================================================
let customParser = {
    'VZ': function(input){
        let items = input.split(/\s/)
        return {
            airlineCode: 'VZ',
            checkInSequenceNumber: items[1],
            seatNumber: items[2]
        }
    },
    'DD': function(input){
        let items = input.split('/')
        return {
            airlineCode: 'DD',
            flightNumber: items[0].substring(2),
            checkInSequenceNumber: items[1], 
        }
    }
}
/*
	airlineCode: "AA",
	firstName: "Poldet",
	lastName: "Assanangkornchai",
	flightNumber: "ABC",
	seatNumber: "1234",
	travelClass: "Economy"
*/
export function parseIataCode(input) {
    input = input.toUpperCase()
	if (!iataRegex.exec(input)){
        let two_char = input.substring(0, 2)
        if(customParser[two_char]){
            return { context: 'airline_ticket', info: customParser[two_char](input) }
        }
		return null
    }
	let result = {}
	_.each(iataFormat, (element, k) => {
		let item = getIataElement(input, element)
		if (item) {
			switch (k) {
				case 'dateOfPassIssuance':
				case 'dateOfFlight':
					item = moment().dayOfYear(item)
					break
				case 'passengerName':
					let __items = item.split('/')
					result.firstName = __items[0]
					result.lastName = __items[1]
					break
				case 'compartmentCode':
					result.travelClass = 	('RPFA'.indexOf(item) >= 0) ? 'First Class' :
										('JCDIZ'.indexOf(item) >= 0) ? 'Business' :
										('WSYBHKLMNQTX'.indexOf(item) >= 0) ? 'Economy' : 'Unknown'
					break
				case 'operatingCarrierDesignator':
					result.airlineCode = item
					break
				default: 
					break
			}
			result[k] = item
		}
	})
	if(result.airlineCode && result.flightNumber)
		result.fullFlightNumber = result.airlineCode + result.flightNumber

	return _.size(_.omit(result, ["formatCode", "numberOfSegments"])) > 0 ? { context: 'airline_ticket', info: result } : null
}

//voucher code format => CRL$VCH__E1838F/MANPRASATKOOL
export function parseCoralVoucherCode(input) {
	if (!voucherRegex.exec(input))
		return null

	let items = input.substring(9).split('/')	//9 is length of coral voucher prefix
	if (_.size(items) !== 2)
		return null

	return { context: 'coral_voucher', info: { confirmationNumber: items[0], lastName: items[1] } }
}

let iataRegex = /^[SM][ 1-4]/
let voucherRegex = /^CRL\$VCH/

export default function parseBarcode(input) {
	let result = parseIataCode(input) || parseCoralVoucherCode(input)
	return result || { context: 'text', info: { text: input } }
}