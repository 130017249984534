export default {
  UPDATE_CHECKIN_SERVICETXN_FIELD: 'UPDATE_CHECKIN_SERVICETXN_FIELD',
  TOUCH_CHECKIN_SERVICETXN_FIELD: 'TOUCH_CHECKIN_SERVICETXN_FIELD',
  LOAD_CHECKIN_WITH_AGENT: 'LOAD_CHECKIN_WITH_AGENT',
  LOAD_CHECKIN_SERVICETXN: 'LOAD_CHECKIN_SERVICETXN',
  SELECT_CHECKIN_TYPE: 'SELECT_CHECKIN_TYPE',
  SUBMIT_CHECKIN: 'SUBMIT_CHECKIN',
  FETCH_CHECKIN: 'FETCH_CHECKIN',
  FETCH_CHECKIN_SUCCESS: 'FETCH_CHECKIN_SUCCESS',
  CLEAR_CHECKIN: 'CLEAR_CHECKIN',
  UPDATE_FILTER_RESERVATION_TEXT: 'UPDATE_FILTER_RESERVATION_TEXT',
  UPDATE_CHECKIN_SERVICETXN_SERVICEINFO:
    'UPDATE_CHECKIN_SERVICETXN_SERVICEINFO',
  USE_VOUCHER_PRIVILEGE: 'USE_VOUCHER_PRIVILEGE',
  USE_COMPLIMENTARY_PRIVILEGE: 'USE_COMPLIMENTARY_PRIVILEGE',
  USE_GUEST_PRIVILEGE: 'USE_GUEST_PRIVILEGE',
  CLEAR_EXTRA_PRIVILEGE: 'CLEAR_EXTRA_PRIVILEGE',
  USE_CODE_SCAN_INFO: 'USE_CODE_SCAN_INFO',
  UPDATE_PRODUCT_PRICE: 'UPDATE_PRODUCT_PRICE',
}
