import React from 'react'
import { Link } from 'react-router-dom'
import Background from '../../../src/img/bg.jpg';

export default ({ to, title }) => (
  <Link to={to}><div className="summaryReport">
    <div className="imageDisplay" style={{ backgroundImage: `url(${Background})` }}>
    </div>
    <div className="title">
      <span>
        {title}
      </span>
    </div>
  </div></Link>
)