import React from 'react'

export default ({ station, onStationClick }) => {
  const handleClick = () => {
    if (station && onStationClick) onStationClick(station)
  }
  return (
    <div className="stationItem" onClick={handleClick}>
      <div className="image">
        <img
          src={station.image || '/assets/images/no_image.png'}
          alt="Station"
        />
      </div>
      <div className="title">
        <span>{station.name}</span>
      </div>
      <div className="sub-title">
        <span>[{station.serviceClass}]</span>
      </div>
    </div>
  )
}
