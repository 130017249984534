import moment from 'moment'
import types from '../constants'
import validate from './checkin.validate'
import initialServiceTxnReducer from './checkin.initial'
import serviceTxnReducer from './checkin.serviceTxn'
import { isEqual } from 'lodash'
import traverse from 'traverse'

export default (state = createInitialState(), action) => {
  switch (action.type) {
    case types.CLEAR_CHECKIN:
      return {
        ...createInitialState(),
        ...validate(createInitialState().serviceTxn),
      }
    default:
      const serviceTxn = serviceTxnReducer(state.serviceTxn, action)
      const initialServiceTxn = initialServiceTxnReducer(
        state.initialServiceTxn,
        action,
      )
      return {
        ...state,
        initialServiceTxn,
        isCreate: isCreate(serviceTxn),
        serviceTxn,
        ...validate(serviceTxn),
        pristine: pristine(state.pristine, action, initialServiceTxn),
        isPristine: isPristine(serviceTxn, initialServiceTxn),
        touch: touch(state.touch, action),
        submitted: action.type === types.SUBMIT_CHECKIN,
        filterReservationText: filterReservationText(
          state.filterReservationText,
          action,
        ),
        checkoutable: checkoutable(serviceTxn),
      }
  }
}

export const isCreate = serviceTxn => serviceTxn.id === undefined

const touch = (state, action) => {
  switch (action.type) {
    case types.TOUCH_CHECKIN_SERVICETXN_FIELD:
      return setObjectValueAtPath(state, action.keyPath, true)
    case types.SELECT_CHECKIN_TYPE:
      return createInitialState().touch
    case types.SUBMIT_CHECKIN:
      return touchedServiceTxn
    default:
      return state
  }
}

const pristine = (state, action, initialServiceTxn) => {
  switch (action.type) {
    case types.UPDATE_CHECKIN_SERVICETXN_FIELD:
      const initialValue = getObjectValueAtPath(
        initialServiceTxn,
        action.keyPath,
      )
      return setObjectValueAtPath(
        state,
        action.keyPath,
        initialValue === action.value,
      )
    case types.SELECT_CHECKIN_TYPE:
      return createInitialState().pristine
    default:
      return state
  }
}

export const setObjectValueAtPath = (obj, path, value) => {
  const newObj = Object.assign({}, obj)
  traverse(newObj).set(path.split('.'), value)
  return newObj
}
export const getObjectValueAtPath = (obj, path) =>
  traverse(obj).get(path.split('.'))

const isPristine = (serviceTxn, initialServiceTxn) => {
  return isEqual(
    { ...serviceTxn, ...initialCheckInType },
    { ...initialServiceTxn, ...initialCheckInType },
  )
}

const filterReservationText = (state, action) => {
  switch (action.type) {
    case types.UPDATE_FILTER_RESERVATION_TEXT:
      return action.text
    case types.FETCH_CHECKIN_SUCCESS:
      return createInitialState().filterReservationText
    default:
      return state
  }
}

const createInitialServiceTxn = () => ({
  reservation: {},
  customerFirstName: '',
  customerLastName: '',
  flightNumber: '',
  seatNumber: '',
  travelClass: '',
  reference1: '',
  reference2: '',
  remark: '',
  serviceInfo: {
    inTime: moment()
      .seconds(0)
      .milliseconds(0),
    stdTime: moment()
      .seconds(0)
      .milliseconds(0),
    guestPaxAdult: 0,
    guestPaxChild: 0,
    guestPaxInfant: 0,
    luggageAmount: 0,
    note: '',
  },
  productId: undefined,
  ...initialCheckInType,
})

const initialCheckInType = {
  checkInType: undefined,
  agentCheckInInfo: undefined,
  airlineCheckInInfo: undefined,
  walkinCheckInInfo: undefined,
}

export const createInitialState = () => ({
  isCreate: true,
  initialServiceTxn: createInitialServiceTxn(),
  serviceTxn: createInitialServiceTxn(),
  pristine: { reservation: { mainPax: {} }, serviceInfo: {} },
  touch: { reservation: { mainPax: {} }, serviceInfo: {} },
  filterReservationText: '',
})

const touchedServiceTxn = {
  reservation: {},
  customerFirstName: true,
  customerLastName: true,
  flightNumber: true,
  seatNumber: true,
  travelClass: true,
  serviceDateTime: true,
  reference1: true,
  reference2: true,
  checkInType: true,
  serviceInfo: {
    inTime: true,
    stdTime: true,
    guestPaxAdult: true,
    guestPaxChild: true,
    guestPaxInfant: true,
    luggageAmount: true,
    note: true,
  },
}

const checkoutable = serviceTxn => {
  if (serviceTxn.status === 'processing') return true

  return false
}
