import React from 'react'
import AssignmentOnQueueTableContainer from './AssignmentOnQueueTableContainer'
import AppBar from '../../components/AppBar'
import storage from '../../storage'
import BackButton from '../../components/BackButton'

export default class AssignmentOnQueueScene extends React.Component {
  handleBackScene = () => {
    if (this.props.history)
      this.props.history.goBack()
  }

  render() {
    const { stationName, serviceDisplayName } = storage.info()
    return (
      <div className="service">
        <AppBar
          header="Assignment"
          subHeader={`On Queue (${stationName} - ${serviceDisplayName})`}
          hasBtnGroup />
        <div className="kiosk-container">
          <div className="kiosk-panel summary-report">
            <div className="col-md-12 col-sm-12 col-xs-12 kiosk-fullheight ">
              <div className="title">
                <BackButton
                  onClick={this.handleBackScene}>
                  Back
                </BackButton>
                On Queue
              </div>
              <hr />
              <AssignmentOnQueueTableContainer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}