import React from 'react'
import AssignmentMenuItemListContainer from './AssignmentMenuItemListContainer'
import AppBar from '../../components/AppBar'

export default () => {
  return (
    <div className="service">
      <AppBar
        header="Assignment"
        hasBtnGroup />
      <div className="kiosk-container">
        <AssignmentMenuItemListContainer />
      </div>
    </div>
  )
}