import React from 'react'
import {
  FormGroup,
  InputGroup,
  FormControl,
  Glyphicon,
} from 'react-bootstrap'

export default ({ value, onChange, placeholder }) => (
  <div className="tab-search-field zero-top">
    <FormGroup>
      <InputGroup>
        <FormControl type="text"
          value={value}
          placeholder={placeholder}
          onChange={e => onChange(e.currentTarget.value)} />
        <InputGroup.Addon>
          <Glyphicon glyph="search" />
        </InputGroup.Addon>
      </InputGroup>
    </FormGroup>
  </div>
)