import { parseFieldToMomentIfNeeded } from './parser'

/* 
	state.fetch = {
		customer: {
			list: [],
			single: {},
			fromIndex: int,
			toIndex: int,
			totalCount: int,
			singleErrors: {
				firstName: 'bad input', ...
			}
		}
	}
*/

const reducer = (state = {}, action) => {
	if (action.type.endsWith('_SUCCESS')) {
		const { meta: { fetch_id, $response: { ok } } } = action
		if (ok)
			return { ...state, [fetch_id]: fetch(state[fetch_id], action) }
		else
			return { ...state, [fetch_id]: { ...state[fetch_id], singleErrors: action.data.errors } }
	} else if (action.type === 'CLEAR_FETCH_DATA') {
		return clearData(state, action)
	}

	return state
}

const fetch = (state = {}, action) => {
	const { data, meta } = action
	const type = meta.type || 'list'
	let result = parseFieldToMomentIfNeeded(data)
	result = transformDataIfNeeded(result, meta.resultTransform)
	switch (type) {
		case 'single':
			return { ...state, single: result }
		case 'create':
			return {
				list: state.list ? [...state.list, result] : undefined,
				single: result,
				totalCount: state.totalCount !== undefined ? state.totalCount + 1 : undefined
			}
		case 'update':
			return {
				...state,
				list: state.list ? state.list.map(i => i._id === result._id ? result : i) : undefined,
				single: result
			}
		case 'delete':
			return {
				list: state.list.filter(item => item._id !== action.meta._id),
				totalCount: state.totalCount !== undefined ? state.totalCount - 1 : undefined
			}
		case 'list':
			const nextState = { ...state, list: result }
			const { $response } = meta
			if ($response) {
				return { ...nextState, ...paginateInfos($response) }
			}
			return nextState
		default:
			return state
	}
}

const transformDataIfNeeded = (data, resultTransform) => {
	if (!resultTransform) return data

	if (Array.isArray(data))
		return data.map(d => resultTransform(d))

	return resultTransform(data)
}

const paginateInfos = $response => {
	const headers = $response.headers
	if (!headers)
		return null
	let totalCount = headers.get('X-Total-Count')
	if (totalCount) {
		return {
			totalCount: Number(totalCount)
		}
	}
	// MARK: Deprecated
	let content_range = headers.get('Content-Range')
	if (content_range) {
		let range_info = /(\d+)-(\d+)\/(\d+)/.exec(content_range)
		if (range_info && range_info.index >= 0) {
			return {
				fromIndex: parseInt(range_info[1], 10),
				toIndex: parseInt(range_info[2], 10),
				totalCount: parseInt(range_info[3], 10),
			}
		}
	}

	return null
}

const clearData = (state, action) => {
	const { meta: { fetch_id, type } } = action
	return {
		...state,
		[fetch_id]: {
			...state[fetch_id],
			[type]: undefined,
		}
	}
}

export default reducer;