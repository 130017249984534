import React from 'react'
import AssignmentMenuItemList from './AssignmentMenuItemList'
import { connect } from 'react-redux'
import { clearReservationCounts, fetchReservationCounts } from '../../actions/remote_api'

class AssignmentMenuItemListContainer extends React.Component {
  componentDidMount() {
    this.props.fetchReservationCounts({
      onQueue: true,
      daily: true,
      createByKiosk: false,
    }, { fetchId: 'reservationOnQueueCount' })

    this.props.fetchReservationCounts({
      pending: true,
      daily: true,
      createByKiosk: false,
    }, { fetchId: 'reservationPendingCount' })
  }

  componentWillUnmount() {
    this.props.clearReservationCounts('list', { fetchId: 'reservationOnQueueCount' })
    this.props.clearReservationCounts('list', { fetchId: 'reservationPendingCount' })
  }

  render() {
    return (
      <AssignmentMenuItemList
        pendingNotificationCount={this.props.reservationPendingCount}
        onQueueNotificationCount={this.props.reservationOnQueueCount} />
    )
  }
}

export default connect(
  ({ fetch: { reservationOnQueueCount, reservationPendingCount } }) => ({
    reservationOnQueueCount: reservationOnQueueCount && reservationOnQueueCount.list ?
      reservationOnQueueCount.list.count : undefined,
    reservationPendingCount: reservationPendingCount && reservationPendingCount.list ?
      reservationPendingCount.list.count : undefined,
  }),
  { fetchReservationCounts, clearReservationCounts }
)(AssignmentMenuItemListContainer)