import React from 'react'
import styled from 'styled-components'

export default ({ airline, onClick }) => (
  <div className="logo-container" onClick={() => onClick(airline)}>
    <AirlineLogoCon>
      {airline.logo &&
        <AirlineLogo title={airline.name} alt={airline.name} src={airline.logo} />
      }
      {!airline.logo &&
        <NoLogo>{airline.code}</NoLogo>
      }
    </AirlineLogoCon>
    <AirlineTitle>{airline.name} ({airline.code})</AirlineTitle>
  </div>
)

const AirlineTitle = styled.span`
font-size: 14px;
flex-grow: 1;
align-self: auto;
margin: auto;
`

const AirlineLogoCon = styled.div`
width: 100%;
height: 80%;
text-align: center;
display: flex;
`

const NoLogo = styled.div`
background: #72421a;
padding: 5px;
width: 46px;
height: 46px;
line-height: 36px;
color: white;
font-weight: bold;
font-size: 26px;
text-align: center;
margin: auto;
border-radius: 28px;
`
const AirlineLogo = styled.img`
max-width: 95px;
max-height: 45px;
margin: auto;
`
