import types from '../constants'
import { createInitialState } from './checkin'
import { parseFieldToMomentIfNeeded } from './parser'

export default (state, action) => {
  switch (action.type) {
    case types.FETCH_CHECKIN_SUCCESS:
      return {
        ...createInitialState().initialServiceTxn,
        ...parseFieldToMomentIfNeeded(action.data)
      }
    case types.LOAD_CHECKIN_SERVICETXN:
      return {
        ...createInitialState().initialServiceTxn,
        ...parseFieldToMomentIfNeeded(action.serviceTxn),
        reservation: {
          ...createInitialState().initialServiceTxn.reservation,
          ...parseFieldToMomentIfNeeded(action.serviceTxn.reservation),
        }
      }
    case types.SELECT_CHECKIN_TYPE:
      return createInitialState().initialServiceTxn
    default:
      return state
  }
}
