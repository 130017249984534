import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Glyphicon } from 'react-bootstrap'
import { connect } from 'react-redux'

const CheckInTypeError = ({ show }) => {
  if (show)
    return <div className="title-kiosk-null text-danger"><Glyphicon style={{ top: 3 }} glyph="info-sign" /> <Translate value="checkInScene.checkInType.nullMsg" /></div>
  return null
}

export default connect(({ checkin }) =>
  ({ show: checkin.errors.checkInType !== undefined && checkin.touch.checkInType })
)(CheckInTypeError)