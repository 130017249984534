import React from 'react'
import styled from 'styled-components'

export const SplitView = ({ children }) => (
  <div className="kiosk-container">
      {children}
  </div>
)

export const LeftPanel = styled.div.attrs({
  className: 'col-md-4 col-sm-4 col-xs-4 left-panel'
}) `
  height: 100%;
  margin-top: 10px;
`

export const RightPanel = styled.div.attrs({
  className: 'col-md-8 col-sm-8 col-xs-8 kiosk-fullheight'
}) ``
