import React from 'react'
import ActiveServiceTxnTableContainer from './ActiveServiceTxnTableContainer'
import * as Badge from './ActiveServiceTxnPanelBadge'
import styled from 'styled-components'
import { Panel } from 'react-bootstrap'
export default class ActiveServiceTxnPanel extends React.Component {

  render() {
    return (
      <div>
        <div className="col-md-12" style={{ marginBottom: 15 }}>
          <hr />
          <Badge.ActiveCustomerAmountBadge />
          <Badge.AlmostTimeoutAmountBadge />
          <Badge.TimeoutAmountBadge />

          <InfoPanel>
            <InfoItem>
              <span className="glyphicon glyphicon-ok"></span><strong>Check-out</strong>
            </InfoItem> | 
            <InfoItem>
              <span className="glyphicon glyphicon-list-alt"></span><strong>Add Customer Feedback</strong>
            </InfoItem> | 
            <InfoItem>
              <span className="glyphicon glyphicon-hourglass"></span><strong>Extend Time Limit</strong>
            </InfoItem>
          </InfoPanel>
        </div>

        <ActiveServiceTxnTableContainer {...this.props} />
      </div>
    )
  }
}
const InfoPanel = styled(Panel) `
float: right;
margin-bottom: 0px; 
`

const InfoItem = styled.span`
  margin-left: 5px; 
  margin-right: 5px; 
  > * {
    margin: auto 4px;
  }
`