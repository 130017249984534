import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Button,
  Col,
} from 'react-bootstrap'
import SearchableStaffSelectListViewContainer from '../../components/SearchableStaffSelectListViewContainer'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

export default ({
  isShow,
  onHide,
  reservation,
  jobAssignment,
  targetStaff,
  onStaffSelect,
  isSubmitValid,
  onSubmit,
}) => {
  if ([reservation, jobAssignment].some(isEmpty))
    return null

  const { staff: currentStaff, service } = jobAssignment
  return (
    <Modal
      show={isShow}
      onHide={onHide}
      backdrop="static"
      bsSize="lg">
      <ModalHeader closeButton>
        <ModalTitle>
          REASSIGN

        </ModalTitle>
      </ModalHeader>
      <ModalBody className="reassign-modal">
        <Col sm={7}>
          <dl className="dl-horizontal">
            <dt>Service:</dt>
            <dd>{service.displayName}</dd>
            <dt>Reservation:</dt>
            <dd>{reservation.confirmationNumber}</dd>
            <dt>Reassign From:</dt>
            <dd>{renderStaffName(currentStaff)}</dd>
            <dt>Reassign To:</dt>
            <dd>{renderAssignTo(targetStaff)}</dd>
          </dl>
        </Col>
        <Col sm={5}>
          <SearchableStaffSelectListViewContainer
            onStaffSelect={onStaffSelect} />
        </Col>
      </ModalBody>

      <Modal.Footer>
        <Button
          bsStyle="primary"
          bsSize="lg"
          onClick={onSubmit}
          disabled={!isSubmitValid}>
          Submit
        </Button>
      </Modal.Footer>

    </Modal >
  )
}

const renderAssignTo = targetStaff => {
  if (targetStaff)
    return (
      <strong>
        {renderStaffName(targetStaff)}
      </strong>
    )
  return (
    <EmptyStaffLabel>Choose staff on the right</EmptyStaffLabel>
  )
}

const EmptyStaffLabel = styled.span.attrs({
  className: 'label label-info'
}) `
  font-size: 20px;
`

const renderStaffName = staff => `${staff.firstName} ${staff.lastName}`