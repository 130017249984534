import React from 'react'
import { connect } from 'react-redux'
import { fetchWorkSummaries } from '../../actions/remote_api'
import WorkSummaryListView from './WorkSummaryListView'
import { isEmpty, debounce } from 'lodash'
import BackButton from '../../components/BackButton'
import styled from 'styled-components'
import { toastr } from 'react-redux-toastr'

class WorkSummaryListViewContainer extends React.Component {
  state = {
    searchText: ''
  }

  constructor(props) {
    super(props)
    this.search = debounce(this.search, 300)
  }

  componentDidMount() {
    this.search()
  }

  handleSearchTextChange = e => {
    this.setState({
      searchText: e.target.value
    }, this.search)
  }

  search = () => {
    const query = { daily: true }
    if (!isEmpty(this.state.searchText)) {
      query.q = this.state.searchText
    }
    toastr.info('Loading')
    this.props.fetchWorkSummaries(query)
  }

  render() {
    return (
      <LeftPanel className="add-bg">
        <div className="col-md-12">
          <div className="title">
            <BackButton
              onClick={this.props.onBack}>
              Back
            </BackButton>
            Work Summary
            </div>
          <hr style={{ marginBottom: 5 }} />
        </div>
        <WorkSummaryListView
          workSummaries={this.props.workSummaries}
          onWorkSummaryClick={this.props.onWorkSummaryClick}
          searchText={this.state.searchText}
          onSearchTextChange={this.handleSearchTextChange} />
      </LeftPanel>
    )
  }
}

const LeftPanel = styled.div`
position: static;
height: 97.6%;
`

export default connect(
  ({ fetch }) => ({ workSummaries: fetch.workSummary ? fetch.workSummary.list : undefined }),
  { fetchWorkSummaries }
)(WorkSummaryListViewContainer)
