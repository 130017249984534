import React from 'react'
import WorkSummaryDetailViewJobAssignmentTableItem from './WorkSummaryDetailViewJobAssignmentTableItem'

export default ({ jobAssignments }) => (
  <table className="table table-kiosk">
    <thead>
      <tr>
        <th>Service</th>
        <th>Passenger</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      {jobAssignments && jobAssignments.map(jobAssignment =>
        <WorkSummaryDetailViewJobAssignmentTableItem
          key={jobAssignment._id}
          jobAssignment={jobAssignment} />
      )}
    </tbody>
  </table>
)