import React from 'react'
import _ from 'lodash'
import { Panel, Button, Badge } from 'react-bootstrap'
import styled from 'styled-components'

export default ({
  extraPrivileges,
  voucherPrivileges,
  complimentaryPrivileges,
  onUseGuestPrivilegeClick,
  onClearExtraPrivilege,
  duration,
  guestOf,
  onTogglePanel,
  isOpen,
}) => {
  const headerInfo = makePanelHeaderInfo(extraPrivileges, duration, guestOf)
  return (
    <div>
      {headerInfo && <Badge onClick={onClearExtraPrivilege} className="extra-privilege-label">{headerInfo} <CloseIcon /></Badge>}
      <Title onClick={onTogglePanel}>
        <h4>
          <b><Icon isOpen={isOpen} /> Extra Privileges </b>
        </h4>
      </Title>

      <Panel
        collapsible
        expanded={isOpen}
        className="no-border panel-kiosk kiosk-form">
        {voucherPrivileges && renderVoucherPrivileges(voucherPrivileges)}
        {complimentaryPrivileges && renderComplimentaryPrivileges(complimentaryPrivileges)}
        <Button
          onClick={onUseGuestPrivilegeClick}
          className="btn btn-primary"
          bsSize="lg"
          block>
          Guest Privilege
          </Button>
      </Panel>
    </div>
  )
}

const renderVoucherPrivileges = (voucherPrivileges) => (
  voucherPrivileges.map(({ duration, useFn }) => (
    <Button
      key={duration}
      onClick={useFn}
      className="btn btn-primary"
      bsSize="lg"
      block>
      Voucher {duration && duration > 0 ? duration : null}
    </Button>
  ))
)

const renderComplimentaryPrivileges = (complimentaryPrivileges) => (
  complimentaryPrivileges.map(({ duration, useFn }) => (
    <Button
      key={duration}
      onClick={useFn}
      className="btn btn-primary"
      bsSize="lg"
      block>
      Complimentary {duration && duration > 0 ? duration : null}
    </Button>
  ))
)

const Icon = ({ isOpen }) => isOpen ? <span className="glyphicon glyphicon-minus" > </span> : <span className="glyphicon glyphicon-plus" />

const CloseIcon = styled.span.attrs({
  className: 'glyphicon glyphicon-remove '
}) `top: 2px;`

const Title = styled.div`
padding-top: 15px;
`
const makePanelHeaderInfo = (extraPrivileges, duration, guestOf) => {
  return (
    extraPrivileges ?
      makeExtraPrivilegeInfo(extraPrivileges, duration, guestOf) :
      undefined
  )
}

const makeExtraPrivilegeInfo = (extraPrivileges, duration, guestOf) => {
  switch (extraPrivileges) {
    case 'guest':
      return `Guest of: ${_.get(guestOf, 'customerName', '-')}`
    case 'voucher':
      return 'Voucher' + (duration && duration > 0 ? `: ${duration}` : '')
    case 'complimentary':
      return 'Complimentary' + (duration && duration > 0 ? `: ${duration}` : '')
    default:
      return '-'
  }
}