import { compose, withHandlers, withPropsOnChange, withProps } from 'recompose'
import { get, isNil } from 'lodash'
import { connect } from 'react-redux'

export const withComplexValue = compose(
  withProps(props => ({
    simpleValue: !isNil(props.selectValue),
  })),
  withHandlers({
    onChange: ({ onChange, simpleValue = false }) => optionOrValue => {
      if (onChange) {
        const value = simpleValue
          ? optionOrValue
          : get(optionOrValue, 'value', null)
        onChange(value)
      }
    },
  }),
  withPropsOnChange(['value'], ({ value, ...props }) => ({
    value: getValue({
      value,
      props,
    }),
  })),
  connect(
    (
      { fetch },
      { selectId = 'searchOptions', createLabel, selectValue, simpleValue },
    ) => ({
      options: get(fetch, `${selectId}.list`, []).map(value => ({
        value: simpleValue ? selectValue(value) : value,
        label: createLabel(value),
      })),
    }),
  ),
)

const getValue = ({ value, props }) => {
  if (isNil(value)) return null
  const { createLabel, simpleValue = false } = props

  return simpleValue
    ? value
    : {
        value,
        label: createLabel(value, props),
      }
}
