export default {
  suffix: ['REQUEST', 'SUCCESS', 'FAILURE'],
  // if `debug` is true, then in reducer `action.meta.$requestOptions`
  debug: true,
  // Set global value by `responseType`. Available values: json, text, formData, blob, arrayBuffer (fetch methods). Default: json
  responseType: 'json',
  // Example config
  fetchOptions: {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }
}