import React from 'react'
import SummaryReportMenuItem from './SummaryReportMenuItem'
import { I18n } from 'react-redux-i18n'
import AppBar from '../../components/AppBar'

export default () => {
  const label = I18n.t('summaryReportScene')
  return (
    <div className="service">
      <AppBar
        header="Summary Report"
        hasBtnGroup />
      <div className="kiosk-container">
        <div className="summaryReport-container">
          <SummaryReportMenuItem to="/summary-report/booking-summary" title={label.bookingSummary.title} />
          <SummaryReportMenuItem to="/summary-report/customer-summary" title={label.customerSummary.title} />
          <SummaryReportMenuItem to="/summary-report/work-summary" title={label.workSummary.title} />
        </div>
      </div>
    </div>
  )
}