import React from 'react'
import { compose } from 'recompose'
import withDailyTimeBoundary from '../../components/ReservationTable/withDailyTimeBoundary'
import withReservationCount from '../../components/ReservationTable/withReservationCount'
import CustomerSummaryTableContainer from './CustomerSummaryTableContainer'
import Badge from '../../components/Badge'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import BackButton from '../../components/BackButton'
import DailyTimeBoundaryLabel from '../../components/DailyTimeBoundaryLabel';

class CustomerSummaryPanelContainer extends React.Component {

  render() {
    const { totalCount, warningCount, dailyTimeBoundary } = this.props
    return (
      <div className="col-md-12 col-sm-12 col-xs-12 ">
        <div className="title">
          <BackButton
            onClick={this.props.onBack}>
            Back
          </BackButton>
          Customer Summary <DailyTimeBoundaryLabel dailyTimeBoundary={dailyTimeBoundary} />
          <hr />
        </div>
        <InfoRow>
          <Col sm={12}>
            <Badge label="Total" data={totalCount} dataStyle="success" />
            <Badge label="Warning" data={warningCount} dataStyle="danger" />
          </Col>
        </InfoRow>
        <CustomerSummaryTableContainer />
      </div>
    )
  }
}

const query = {
  createByKiosk: false,
  daily: true,
}

const enhance = compose(
  withDailyTimeBoundary,
  withReservationCount(query),
)

const InfoRow = styled(Row) `
margin-bottom: 10px;
`
export default enhance(
  CustomerSummaryPanelContainer
)