import React from 'react'
import ReservationCheckInType from './ReservationCheckInType'
import {
  fetchReservations,
  clearReservation,
} from '../../actions/remote_api'
import { updateFilterReservationText } from '../../actions/checkin'
import { connect } from 'react-redux'
import { debounce, isEmpty } from 'lodash'
import { info } from '../../storage'

class ReservationCheckInTypeContainer extends React.Component {
  state = {
    filteredReservations: [],
  }

  constructor(props) {
    super(props)
    this.filterReservations = debounce(this.filterReservations, 300)
  }

  filterReservations = () => {
    if (this.shouldFilterReservation())
      this.props.fetchReservations({
        q: !isEmpty(this.props.filterReservationText) ? this.props.filterReservationText : undefined,
        serviceId: info().serviceId,
        createByKiosk: false,
        operationDaily: true,
        checkin: true,
        limit: 10,
      })
    else {
      this.setState({ ...this.state, filteredReservations: [] })
    }
  }

  shouldFilterReservation = () => this.props.filterReservationText && this.props.filterReservationText.length > 2

  handleFilterTextChange = filterText =>
    this.props.updateFilterReservationText(filterText)

  componentWillReceiveProps(nextProps) {
    if (this.props.filterReservationText !== nextProps.filterReservationText) {
      this.filterReservations()
    }
    if (nextProps.reservations) {
      this.setState({ ...this.state, filteredReservations: nextProps.reservations })
      this.props.clearReservation('list')
    }
  }

  componentDidMount() {
    if (this.shouldFilterReservation()) {
      this.filterReservations()
    }
  }

  componentWillUnmount() {
    this.props.clearReservation('list')
  }

  render() {
    const { onReservationSelect } = this.props
    return (
      <ReservationCheckInType
        reservations={this.state.filteredReservations}
        filterText={this.props.filterReservationText}
        onFilterTextChange={this.handleFilterTextChange}
        onReservationSelect={onReservationSelect} />
    )
  }
}

export default connect(
  ({ fetch, checkin }) => ({
    reservations: fetch.reservation ? fetch.reservation.list : undefined,
    submitted: checkin.submitted,
    filterReservationText: checkin.filterReservationText,
  }),
  {
    fetchReservations,
    clearReservation,
    updateFilterReservationText,
  }
)(ReservationCheckInTypeContainer)