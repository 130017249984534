import React from 'react'
import { fetchServiceTxns, clearServiceTxn } from '../actions/remote_api'
import { connect } from 'react-redux'
import ServiceTxnListViewContainer from './ServiceTxnListView'
import { isEmpty, debounce } from 'lodash'
import SearchField from './SearchField'
import { info } from '../storage'
import { escape } from '../utils/regex'

class SearchableServiceTxnListViewContainer extends React.Component {

  state = {
    searchText: '',
  }

  constructor(props) {
    super(props)
    this.filterServiceTxns = debounce(this.filterServiceTxns, 300)
    
    this.props.clearServiceTxn('list')
    this.info = info()
  }

  handleSearchTextChange = (searchText) => {
    this.setState({
      searchText,
    }, this.filterServiceTxns)
  }

  filterServiceTxns = () => {
    if (!isEmpty(this.state.searchText) && this.state.searchText.length > 2)
      this.props.fetchServiceTxns({
        reservationMainPaxName_like: escape(this.state.searchText),
        limit: 10,
        'service._id': this.info.serviceId,
        statusConfirmAndGreater: true,
      })
    else
      this.props.clearServiceTxn('list')
  }

  componentWillUnmount() {
    this.props.clearServiceTxn('list')
  }

  render() {
    return (
      <div>
        <SearchField
          value={this.state.searchText}
          placeholder="type passenger name"
          onChange={this.handleSearchTextChange} />
        <ServiceTxnListViewContainer
          onServiceTxnSelect={this.props.onServiceTxnSelect} />
      </div>
    )
  }
}

export default connect(
  null, { fetchServiceTxns, clearServiceTxn }
)(SearchableServiceTxnListViewContainer)
