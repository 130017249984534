import React from 'react'
import ReservationTableContainer from '../../components/ReservationTable'
import { connect } from 'react-redux'
import { fetchReservations, clearReservation } from '../../actions/remote_api'
import ComplainModal from '../../components/ComplainModal'
import { toastr } from 'react-redux-toastr'

class CustomerSummaryTableContainer extends React.Component {

  state = {
    isFeedbackModalOpen: false,
    serviceTxnToFeedback: undefined,
  }

  fetchInstances = (params) => {
    toastr.info('Loading')
    this.props.fetchReservations({
      ...params,
      // createByKiosk: false,
      daily: true,
      sort: 'serviceDateTime',
      order: 'desc',
      summaryReport: true,
    })
  }

  openFeedbackModal = serviceTxn => {
    this.setState({
      ...this.state,
      isFeedbackModalOpen: true,
      serviceTxnToFeedback: serviceTxn
    })
  }

  closeFeedbackModal = () => {
    this.setState({
      ...this.state,
      isFeedbackModalOpen: false,
      serviceTxnToFeedback: undefined,
    })
  }

  clearInstances = () => {
    this.props.clearReservation('list')
  }

  render() {
    return (
      <div>
        <ReservationTableContainer
          fetchInstances={this.fetchInstances}
          clearInstances={this.clearInstances}
          onFeedbackClick={this.openFeedbackModal}
          isOutTimeShow={true}
          isCustomerSatisfactionShow={true}
          {...this.props} />

        <ComplainModal
          isShow={this.state.isFeedbackModalOpen}
          onHide={this.closeFeedbackModal}
          serviceTxn={this.state.serviceTxnToFeedback}
          onFeedback={this.handleFeedback} />
      </div>
    )
  }
}

export default connect(
  ({ fetch }) => ({
    instances: fetch.reservation ? fetch.reservation.list : undefined,
    totalCount: fetch.reservation ? fetch.reservation.totalCount : undefined,
  }),
  { fetchReservations, clearReservation }
)(CustomerSummaryTableContainer)
