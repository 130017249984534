import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PrivateRoute from './auth/PrivateRoute'
import auth_routes from './auth/auth_routes'
import {
  MenuScene, StationScene, CheckInScene,
  StationReportScene,
  BookingSummaryScene, CustomerSummaryScene, WorkSummaryScene, SummaryReportScene,
  AssignmentScene, AssignmentStaffScene, AssignmentPendingScene, AssignmentOnQueueScene,
  MarkFinalServiceTxnScene,
} from '../scenes'
// import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { hasInfo } from '../storage'

class AppRouter extends React.Component {
  isFirstLaunch = true

  shouldRedirectToCheckIn() {
    let shouldRedirectToCheckIn = false
    if (this.isFirstLaunch && hasInfo())
      shouldRedirectToCheckIn = true

    if (this.isFirstLaunch)
      this.isFirstLaunch = false

    return shouldRedirectToCheckIn
  }

  shouldRedirectToServiceMenu() {
    return !hasInfo()
  }

  componentDidMount() {
    if (this.shouldRedirectToServiceMenu())
      this.props.history.replace('/')

    if (this.shouldRedirectToCheckIn() && process.env['NODE_ENV'] === 'production')
      this.props.history.replace('/checkin')
  }

  render() {
    const { location } = this.props

    return (
      <Switch location={location}>
        <Route
          exact path="/"
          component={MenuScene} />
        <PrivateRoute
          exact path="/stations"
          component={StationScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/checkin"
          component={CheckInScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/station-report"
          component={StationReportScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/summary-report/booking-summary"
          component={BookingSummaryScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/summary-report/customer-summary"
          component={CustomerSummaryScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/summary-report/work-summary"
          component={WorkSummaryScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/summary-report"
          component={SummaryReportScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/assignment/staff"
          component={AssignmentStaffScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/assignment/pending"
          component={AssignmentPendingScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/assignment/on-queue"
          component={AssignmentOnQueueScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/assignment"
          component={AssignmentScene}
          ifAnyGranted={['user', 'report']} />
        <PrivateRoute
          path="/mark-final"
          component={MarkFinalServiceTxnScene}
          ifAnyGranted={['user', 'report']} />
        {auth_routes()}
      </Switch>
    )
  }
}

export default withRouter(connect(({ auth }) => ({ auth }))(AppRouter))