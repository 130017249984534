import React from 'react'
import { connect } from 'react-redux'
import { fetchStation, fetchPaxCountByStation } from '../actions/remote_api'
import { info } from '../storage'
import styled from 'styled-components'

class KioskCapacityLabel extends React.Component {
  componentDidMount() {
    this.fetchStation()
    this.fetchActivePaxCount()
  }

  fetchActivePaxCount() {
    const { stationId } = info()
    this.props.fetchPaxCountByStation(stationId)
  }

  fetchStation() {
    const { stationId } = info()
    this.props.fetchStation(stationId)
  }

  renderCapacity() {
    const { activePaxCount, stationCapacity } = this.props
    const isActiveServiceTxnCountFetched = activePaxCount !== undefined
    if (this.hasCapacity() && isActiveServiceTxnCountFetched) {
      return `${activePaxCount}/${stationCapacity}`
    }
    return isActiveServiceTxnCountFetched ? `${activePaxCount}` : null
  }

  isLimitExceed() {
    const { activeServiceTxnCount, stationCapacity } = this.props
    if (!this.hasCapacity()) return false
    return activeServiceTxnCount > stationCapacity
  }

  hasCapacity() {
    return this.props.stationCapacity !== undefined
  }

  render() {
    return (
      <CapacityLabel
        isLimitExceed={this.isLimitExceed()}
        hasCapacity={this.hasCapacity()}
      >
        {this.renderCapacity()}
      </CapacityLabel>
    )
  }
}

const CapacityLabel = styled.div`
  background: ${props => (!props.isLimitExceed ? lightWhiteColor : redColor)};
  padding: 5px;
  width: 46px;
  height: 46px;
  line-height: 38px;
  color: ${props => (!props.isLimitExceed ? brownColor : whiteColor)};
  font-weight: bold;
  font-size: ${({ hasCapacity }) =>
    hasCapacity ? hasCapacityFontSize : noCapacityFontSize};
  text-align: center;
  margin: auto;
  border-radius: 28px;
`

const lightWhiteColor = '#eaebe9'
const redColor = '#d9534f'

const brownColor = '#644220'
const whiteColor = 'white'

const hasCapacityFontSize = '14px'
const noCapacityFontSize = '18px'

export default connect(
  ({ fetch: { serviceTxnCount, activePaxCount, station } }) => ({
    activePaxCount:
      activePaxCount && activePaxCount.single
        ? activePaxCount.single.paxCount
        : undefined,
    stationCapacity:
      station && station.single ? station.single.capacity : undefined,
  }),
  {
    fetchStation,
    fetchPaxCountByStation,
  },
)(KioskCapacityLabel)
