import { combineReducers } from 'redux'
import { i18nReducer as i18n } from 'react-redux-i18n'
import { reducer as toastr } from 'react-redux-toastr'
import { reducer as form } from 'redux-form'
import auth from './auth'
import fetch from './fetch'
import checkin from './checkin'

export default combineReducers({
  auth,
  fetch,
  i18n,
  toastr,
  form,
  checkin,
});