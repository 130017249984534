const removeUndefinedFromObject = obj => {
  let newObj = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    if (value !== undefined)
      newObj[key] = value
  })
  return newObj
}

export const createURL = (url, query) => {
  if (query) {
    const { page, limit, sort, order, ...rest } = query
    return url + '?' + new URLSearchParams(removeUndefinedFromObject({
      ...rest,
      _page: page,
      _limit: limit,
      _sort: sort,
      _order: order,
    }))
  }
  return url
}

export const createCRUDActions = (fetchId, url) => ({
  list: (query, options = {}) => {
    const _fetchId = options.fetchId ? options.fetchId : fetchId
    return ({
      type: `FETCH_${_fetchId.toUpperCase()}_LIST`,
      meta: { fetch_id: _fetchId },
      $payload: {
        url: createURL(url, query)
      }
    })
  },

  get: (id, resultTransform) => ({
    type: `FETCH_${fetchId.toUpperCase()}_SINGLE`,
    meta: { fetch_id: fetchId, type: 'single', resultTransform },
    $payload: {
      url: `${url}/${id}`
    }
  }),

  create: (resource, onResponse, resultTransform) => ({
    type: `CREATE_${fetchId.toUpperCase()}`,
    meta: { fetch_id: fetchId, type: 'create', resultTransform },
    $payload: {
      url: url,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify(resource)
      },
      onResponse
    }
  }),

  update: (resource, onResponse, resultTransform) => ({
    type: `UPDATE_${fetchId.toUpperCase()}`,
    meta: { fetch_id: fetchId, type: 'update', resultTransform },
    $payload: {
      url: `${url}/${resource._id}`,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: "PUT",
        body: JSON.stringify(resource)
      },
      onResponse
    }
  }),

  delete: (_id, onResponse, resultTransform) => ({
    type: `DELETE_${fetchId.toUpperCase()}`,
    meta: { fetch_id: fetchId, type: 'delete', _id, resultTransform },
    $payload: {
      url: `${url}/${_id}`,
      options: {
        headers: { 'Content-Type': 'application/json' },
        method: "DELETE"
      },
      responseType: 'text',
      onResponse
    }
  }),

  clear: (type = 'single', options = {}) => ({
    type: 'CLEAR_FETCH_DATA',
    meta: { fetch_id: options.fetchId || fetchId, type }
  })
})
