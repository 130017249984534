import React from 'react'
import WalkinCheckInType from './WalkinCheckInType'
import {
  fetchCards,
  clearCard,
} from '../../actions/remote_api'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import CardPrivilegeSelectModal from './CardPrivilegeSelectModal'
import { info } from '../../storage'
import styled from 'styled-components'
class WalkinCheckInTypeContainer extends React.Component {
  state = {
    filterText: undefined,
    cardPrivilegeSelectModal: {
      isShow: false,
      card: undefined,
    },
  }

  constructor(props) {
    super(props)
    this.fetchCards = debounce(this.fetchCards, 300)
  }

  fetchCards = () => this.props.fetchCards({ q: this.state.filterText, limit: 10 })

  handleFilterTextChange = filterText =>
    this.setState({ filterText }, this.fetchCards)

  componentDidMount() {
    this.fetchCards()
  }

  componentWillUnmount() {
    this.props.clearCard('list')
  }

  handleCardSelect = card => {
    // if (info().serviceName === 'Lounge')
    this.showCardPrivilegeSelectModal(card)
    // else
    //   this.props.onCardSelect(card)
  }

  showCardPrivilegeSelectModal = card => {
    this.setState({
      ...this.state,
      isShowCardPrivilegeSelectModal: true,
      cardPrivilegeSelectModal: {
        isShow: true,
        card,
        classToServiceMapping: this.getClassToServiceMapping(card)
      }
    })
  }

  getClassToServiceMapping = card => {
    const { serviceName, serviceClass } = info()
    return card.classToServiceMapping.filter(m =>
      m.service === serviceName &&
      m.serviceClass === serviceClass
    )
  }

  handleCardPrivilegeSelectClose = () => {
    this.setState({
      ...this.state,
      cardPrivilegeSelectModal: {
        isShow: false,
      }
    })
  }

  handleCardPrivilegeSelectClick = (card, classToServiceMapping) => {
    this.props.onCardSelect({
      ...card,
      privilegeClass: classToServiceMapping.privilegeClass,
      classToServiceMapping: card.classToServiceMapping,
    })
    this.handleCardPrivilegeSelectClose()
  }

  render() {
    const {
      cards, onCashSelect, onCreditCardSelect, onOthersSelect
    } = this.props
    return (
      <FullHeightDiv >
        <WalkinCheckInType cards={cards}
          onFilterTextChange={this.handleFilterTextChange}
          onCashSelect={onCashSelect}
          onCreditCardSelect={onCreditCardSelect}
          onOthersSelect={onOthersSelect}
          onCardSelect={this.handleCardSelect} />
        <CardPrivilegeSelectModal
          isShow={this.state.cardPrivilegeSelectModal.isShow}
          onHide={this.handleCardPrivilegeSelectClose}
          onCardPrivilegeSelect={this.handleCardPrivilegeSelectClick}
          card={this.state.cardPrivilegeSelectModal.card}
          classToServiceMapping={this.state.cardPrivilegeSelectModal.classToServiceMapping} />
      </FullHeightDiv>
    )
  }
}

export default connect(
  ({ fetch }) => ({
    cards: fetch.card ? fetch.card.list : undefined
  }),
  {
    fetchCards,
    clearCard
  }
)(WalkinCheckInTypeContainer)

const FullHeightDiv = styled.div`
  height: 100%;
`