import React from 'react'
import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const dateFormat = dateString => moment(dateString).format(I18n.t('date.display'))

export const timeFormat = dateString => moment(dateString).format(I18n.t('time.display'))

export const dateTimeFormat = dateString => moment(dateString).format(I18n.t('dateTime.display'))

const tooltip = <Tooltip id="tooltip">Click to view details</Tooltip>

export const makeDetailLink = onOpenDetailPopup => (cell, row) =>
  <OverlayTrigger placement="top" overlay={tooltip}>
    <a onClick={() => onOpenDetailPopup(row._id)} role="button"><u>{cell}</u></a>
  </OverlayTrigger>

export const renderClassToServiceMapping = classToServiceMapping => {
  const empytClassToServiceMappingMsg = I18n.t('cardPage.table.empytClassToServiceMappingMsg')
  if (classToServiceMapping)
    return (
      <ListItemTable>
        <tbody>
          {classToServiceMapping.map((c, index) =>
            <tr key={index}>
              <td width="30%">{c.privilegeClass}</td>
              <td width="30%">{c.serviceClass}</td>
              <td width="30%">{c.service}</td>
              <td width="10%">{c.duration ? c.duration : '-'}</td>
            </tr>
          )}
        </tbody>
      </ListItemTable>
    )
  return (<div>{empytClassToServiceMappingMsg}</div>)
}

export const ListItemTable = styled.table.attrs({
  className: 'table',
}) `
  background-color: rgba(0, 0, 0, 0) !important;

  tr:first-child {
    border-top: hidden;

    td {
      padding-top: 0px;
    }
  }

  tr:last-child td {
    padding-bottom: 0px;
  }
`

export { default as withTableConfigs } from './withTableConfigs'

const Thumbnail = styled.img` 
  max-height: ${props => props.maxHeight}; 
  max-width: ${props => props.maxWidth}; 
`

class ThumbnailContainer extends React.Component {
  state = {
    thumbnail: this.props.thumbnail || '/images/no_image.png'
  }

  handleError = () => {
    this.setState({ thumbnail: '/images/no_image.png' })
  }

  render() {
    return <Thumbnail {...this.props}
      src={this.state.thumbnail}
      className="img-responsive center-block"
      alt="thumbnail"
      onError={this.handleError} />
  }
}

export const makeRenderThumbnail = props => thumbnail =>
  <ThumbnailContainer {...props}
    thumbnail={thumbnail} />

// MARK: - Filter

export { selectFilterType } from './SelectFilter'