import React from 'react'

export default ({ workSummary, onWorkSummaryClick }) => (

  <div className="row row-reservation" style={{ marginBottom: '3%' }} onClick={() => onWorkSummaryClick(workSummary)}>
    <div className="col-xs-12 btn btn-reservation btn-large">
      <div className="col-sm-7">
        <p className="main-text">
          {workSummary.staff.firstName} {workSummary.staff.lastName}
        </p>
      </div>
      <div className="col-sm-5 text-left">
        <p className="paxName">
          Incoming: {workSummary.incomingJobCount}
        </p>
        <p className="paxName">
          Completed: {workSummary.completedJobCount}
        </p>
      </div>
    </div>
  </div>

)