import React from 'react'
import StaffSelectListView from './StaffSelectListView'
import { fetchStaffs, clearStaff } from '../actions/remote_api'
import { connect } from 'react-redux'
import SearchField from './SearchField'
import { isEmpty, debounce } from 'lodash'

class SearchableStaffSelectListViewContainer extends React.Component {
  state = {
    searchText: '',
  }

  constructor(props) {
    super(props)
    this.filterStaffs = debounce(this.filterStaffs, 300)
  }

  handleSearchTextChange = (searchText) => {
    this.setState({
      searchText,
    }, this.filterStaffs)
  }

  filterStaffs = () => {
    if (!isEmpty(this.state.searchText) && this.state.searchText.length > 2)
      this.props.fetchStaffs({
        q: this.state.searchText,
        limit: 10,
      })
    else
      this.props.clearStaff('list')
  }

  componentWillUnmount() {
    this.props.clearStaff('list')
  }

  render() {
    return (
      <div>
        <SearchField
          value={this.state.searchText}
          placeholder="TYPE NAME OF STAFF"
          onChange={this.handleSearchTextChange} />
        <StaffSelectListView 
          onStaffSelect={this.props.onStaffSelect} />
      </div>
    )
  }
}

export default connect(
  null,
  { fetchStaffs, clearStaff }
)(SearchableStaffSelectListViewContainer)