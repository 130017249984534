import React from 'react'
import Background from '../../img/bg.jpg';

export default ({ service, onServiceClick }) => {
  const handleClick = () => {
    if (service && onServiceClick)
      onServiceClick(service)
  }

  return (
    <a onClick={handleClick}>
      <div className="serviceItem">
        <div className="imageDisplay" style={{ backgroundImage: `url(${Background})` }}>
        </div>
        <div className="title">
          <span>
            {service.name.toUpperCase()}
          </span>
        </div>
      </div>
    </a>
  )
}