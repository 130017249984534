import React from 'react'
import AirlineCheckInType from './AirlineCheckInType'
import {
  fetchAirlines,
  clearAirline,
} from '../../actions/remote_api'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

class AirlineCheckInTypeContainer extends React.Component {
  state = {
    filterText: undefined
  }

  constructor(props) {
    super(props)
    this.fetchAirlines = debounce(this.fetchAirlines, 300)
  }

  fetchAirlines = () => this.props.fetchAirlines({ q: this.state.filterText, limit: 10 })

  handleFilterTextChange = filterText =>
    this.setState({ filterText }, this.fetchAirlines)

  componentDidMount() {
    this.fetchAirlines()
  }

  componentWillUnmount() {
    this.props.clearAirline('list')
  }

  render() {
    const { airlines, onAirlineSelect } = this.props
    return (
      <AirlineCheckInType airlines={airlines}
        onFilterTextChange={this.handleFilterTextChange}
        onAirlineSelect={onAirlineSelect} />
    )
  }
}

export default connect(
  ({ fetch }) => ({
    airlines: fetch.airline ? fetch.airline.list : undefined
  }),
  {
    fetchAirlines,
    clearAirline
  }
)(AirlineCheckInTypeContainer)