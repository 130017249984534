import moment from 'moment'

export const required = value => value === undefined || value === '' || value === null ? 'Required' : undefined

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined

export const date = value => value && !moment.isMoment(value) ? 'Invalid date/time' : undefined

export const validateWith = (value, validates) => {
  for (let i in validates) {
    const error = validates[i](value)
    if (error)
      return error
  }
  return undefined
}

export default {
  required,
  email,
  validateWith
}