import React from 'react'

export default class LoginFormContainer extends React.Component {
  state = { username: '', password: '' }

  handleChange = (key, value) =>
    this.setState({ ...this.state, [key]: value })

  render() {
    const { children } = this.props
    const { username, password } = this.state
    return (
      React.cloneElement(children, { username, password, onChange: this.handleChange })
    )
  }
}