import React from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'react-bootstrap'
import { isEmpty } from 'lodash'
import SearchableServiceTxnListViewContainer from '../../components/SearchableServiceTxnListViewContainer'
import { ServiceTxnListViewItem } from '../../components/ServiceTxnListView'

export default ({
  isShow,
  onHide,
  recentlyServiceTxns,
  onServiceTxnClick,
}) => (
    <Modal
      className="top-reduce-modal"
      show={isShow}
      onHide={onHide}
      bsSize="lg">
      <ModalHeader closeButton>
        <Modal.Title><b>Guest Privilege</b></Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <h3>Recently Check-Ins</h3>
            <ListGroup>
              {renderRecentlyServiceTxns(
                recentlyServiceTxns,
                onServiceTxnClick
              )}
            </ListGroup>
          </Col>
          <Col md={6}>
            <h3>Search Check-Ins</h3>
            <SearchableServiceTxnListViewContainer
              onServiceTxnSelect={onServiceTxnClick} />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button>Confirm</Button>
      </ModalFooter>
    </Modal>
  )

const renderRecentlyServiceTxns = (
  recentlyServiceTxns,
  onServiceTxnClick,
) => {
  if (isEmpty(recentlyServiceTxns))
    return (
      <ListGroupItem className="header-center text-center" header="No recently check-ins"></ListGroupItem>
    )

  return recentlyServiceTxns.map(recentlyServiceTxn => (
    <ServiceTxnListViewItem
      key={recentlyServiceTxn._id}
      serviceTxn={recentlyServiceTxn}
      onClick={() => onServiceTxnClick(recentlyServiceTxn)} />
  ))
}