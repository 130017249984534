import React from 'react'
import WorkSummaryListViewItem from './WorkSummaryListViewItem'
import { ListGroup, FormGroup, InputGroup, FormControl, Glyphicon } from 'react-bootstrap'
import styled from 'styled-components'

export default ({
  workSummaries,
  onWorkSummaryClick,
  searchText,
  onSearchTextChange,
}) => {
  return (
    <Container>
      <div className="col-md-12 tab-search-field">
        <FormGroup>
          <InputGroup>
            <FormControl
              type="text"
              value={searchText}
              onChange={onSearchTextChange} />
            <InputGroup.Addon>
              <Glyphicon glyph="search" />
            </InputGroup.Addon>
          </InputGroup>
        </FormGroup>
      </div>
      <ScrollContainer>
        <ListGroup>
          {workSummaries && workSummaries.map(workSummary =>
            <WorkSummaryListViewItem
              key={workSummary.staff._id}
              workSummary={workSummary}
              onWorkSummaryClick={onWorkSummaryClick} />
          )}
          
        </ListGroup>
      </ScrollContainer>
    </Container>
  )
}

const Container = styled.div`
height: 100%;
`
const ScrollContainer = styled.div`
height: 71%;
width: 98%;
overflow: auto;
margin-top: 2%;
position: relative;
z-index: 100;
`