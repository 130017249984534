import React from 'react'
import { I18n } from 'react-redux-i18n'

export default ({ jobAssignment }) => (
  <tr>
    <td>
      {jobAssignment.service.displayName}
    </td>
    <td>
      {jobAssignment.reservation.mainPax.fullName}
    </td>
    <td>
      {jobAssignment.issuedServiceDateTime.format(I18n.t('date.shortDateTimeFormat'))}
    </td>
  </tr>
)