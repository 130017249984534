import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import StarRatingComponent from 'react-star-rating-component'
import { Icon } from 'react-icons-kit'
import { androidAlert } from 'react-icons-kit/ionicons'
import styledComponents from 'styled-components'

import { withTableConfigs } from '../../components/table'
import ReservationTableFilterPanel from './ReservationTableFilterPanel'
import ReservationTableJobAssignmentColumn from './ReservationTableJobAssignmentColumn'
import CheckInInfo from '../serviceTxn/CheckInInfo'

const ReservationTable = ({
  defaultOptions,
  defaultSettings,
  settings,
  onCheckoutClick,
  onFeedbackClick,
  renderFilterPanel,
  renderToolbar,
  isCustomerSatisfactionShow = true,
  isAssignable = false,
  onAssignClick,
  onAcceptClick,
  isOutTimeShow = false,
  ...props
}) => {
  return (
    <div>
      {renderFilterPanel}
      {renderToolbar}
      <BootstrapTable
        {...defaultSettings}
        {...settings}
        bodyStyle={{ height: '40vh' }}
        trClassName={rowClassNameFormat}
        options={defaultOptions}
      >
        <TableHeaderColumn
          headerAlign="center"
          width="150px"
          dataField="mainPax"
          dataFormat={mainPax => mainPax.fullName}
        >
          Passenger Name
        </TableHeaderColumn>
        <TableHeaderColumn
          headerAlign="center"
          width="150px"
          dataField="product"
          dataFormat={renderProduct}
        >
          Product
        </TableHeaderColumn>
        <TableHeaderColumn
          headerAlign="center"
          dataField="status"
          dataAlign="center"
          width="100px"
          dataFormat={status => status.toUpperCase()}
        >
          Status
        </TableHeaderColumn>
        <TableHeaderColumn
          headerAlign="center"
          dataAlign="center"
          dataField="serviceDateTime"
          width="100px"
          dataFormat={renderDateTime}
        >
          Service Date/Time
        </TableHeaderColumn>
        {isAssignable && (
          <TableHeaderColumn
            headerAlign="center"
            width="400px"
            dataField="jobAssignments"
            dataFormat={createRenderJobAssignments(
              onAssignClick,
              onAcceptClick,
            )}
          >
            Job Assignments
          </TableHeaderColumn>
        )}
        {isOutTimeShow && (
          <TableHeaderColumn
            headerAlign="center"
            dataField="relatedServiceTxns"
            width="400px"
            dataFormat={renderServiceTxns}
          >
            Services & Out Time
          </TableHeaderColumn>
        )}
        {isCustomerSatisfactionShow && (
          <TableHeaderColumn
            headerAlign="center"
            width="100px"
            dataFormat={renderCustomerRating(onFeedbackClick)}
          >
            Customer Rating
          </TableHeaderColumn>
        )}
        {/* <TableHeaderColumn
        dataField="serviceInfo"
        dataFormat={({ issuedOutTime }) => renderTime(issuedOutTime)}>
        issuedOutTime
        </TableHeaderColumn> */}
      </BootstrapTable>
    </div>
  )
}

const rowClassNameFormat = (reservation, rowIdx) => {
  return reservation.flag === 'overTime' ? 'danger' : 'default'
}

const renderProduct = (product, serviceTxn) => product.name

const createRenderJobAssignments = (onAssignClick, onAcceptClick) => (
  jobAssignments,
  reservation,
) => (
  <ReservationTableJobAssignmentColumn
    jobAssignments={jobAssignments}
    reservation={reservation}
    onAssignClick={onAssignClick}
    onAcceptClick={onAcceptClick}
  />
)

const renderDateTime = dateTime => {
  return dateTime ? dateTime.format(I18n.t('date.shortDateTimeFormat')) : '-'
}

const renderServiceTxns = serviceTxns => (
  <NoMarginRow>
    {serviceTxns &&
      serviceTxns.map(serviceTxn => (
        <Row key={serviceTxn._id}>
          <Col md={5}>{serviceTxn.service.displayName}</Col>
          <Col md={5}>
            <CheckInInfo serviceTxn={serviceTxn} />
          </Col>
          <Col md={2}>{renderServiceInfoOutTime(serviceTxn.serviceInfo)}</Col>
        </Row>
      ))}
  </NoMarginRow>
)

const NoMarginRow = styledComponents(Row)`
  margin-right: 0;
  margin-left: 0;
`

const renderServiceInfoOutTime = serviceInfo => {
  return serviceInfo && serviceInfo.outTime
    ? renderTime(serviceInfo.outTime)
    : null
}

const renderTime = dateTime => {
  return dateTime ? dateTime.format(I18n.t('date.timeFormat')) : '-'
}

const renderCustomerRating = onFeedbackClick => (_, reservationTxn) => {
  const { relatedServiceTxn } = reservationTxn

  if (!relatedServiceTxn || !relatedServiceTxn.serviceInfo) return null

  const customerSatisfactionRate =
    relatedServiceTxn.serviceInfo.customerSatisfactionRate

  return (
    <div>
      <StarRatingComponent
        name="customerSatisfactionRate"
        value={customerSatisfactionRate}
        editing={false}
        starCount={3}
      />

      {relatedServiceTxn.alertFlag && (
        <Icon
          className="text-danger"
          onClick={() => onFeedbackClick(relatedServiceTxn)}
          icon={androidAlert}
          size={24}
        />
      )}
    </div>
  )
}

export default withTableConfigs(ReservationTable, ReservationTableFilterPanel)
