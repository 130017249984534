import React from 'react'
import { ExtraChargeModalBody } from "./ExtraChargeModalBody";
import { toastr } from 'react-redux-toastr'

export const showExtraChargeModalIfNeeded = (serviceTxn, callback) => {
  if (!serviceTxn.isExtraCharged && isExtraCharged(serviceTxn)) {
    serviceTxn.isExtraCharged = isExtraCharged(serviceTxn)
    toastr.confirm(
      'Extra Charge Alert',
      {
        okText: 'Charge',
        onOk: callback,
        component: () => <ExtraChargeModalBody serviceTxn={serviceTxn} />
      }
    )
  } else
    callback()
}

// export const isExtraCharged = (serviceTxn) => {
//   const { serviceInfo, reservation } = serviceTxn
//   if (!serviceTxn._id)
//     return false
//   return (
//     ((serviceInfo.guestPaxAdult || 0) > (reservation.guestPaxAdult || 0)) ||
//     ((serviceInfo.guestPaxChild || 0) > (reservation.guestPaxChild || 0)) ||
//     ((serviceInfo.guestPaxInfant || 0) > (reservation.guestPaxInfant || 0)) ||
//     ((serviceInfo.luggageAmount || 0) > (reservation.luggageAmount || 0))
//   )
// }

export const isExtraCharged = (serviceTxn) => {
  const { serviceInfo, reservation } = serviceTxn
  if (!serviceTxn._id)
    return false
  return (
    ((serviceInfo.guestPaxAdult || 0) > (reservation.guestPaxAdult || 0)) ||
    ((serviceInfo.guestPaxChild || 0) > (reservation.guestPaxChild || 0)) ||
    ((serviceInfo.guestPaxInfant || 0) > (reservation.guestPaxInfant || 0))
  )
}