import types from '../constants'
import { fetchServiceTxns, fetchAirlineById, fetchCardById } from '../api'

export const updateCheckInServiceTxnField = (keyPath, value) => ({
  type: types.UPDATE_CHECKIN_SERVICETXN_FIELD,
  keyPath,
  value,
})

export const touchCheckInServiceTxnField = keyPath => ({
  type: types.TOUCH_CHECKIN_SERVICETXN_FIELD,
  keyPath,
})

export const selectReservation = (
  reservation,
  serviceId,
  onCompleted,
) => async dispatch => {
  const serviceTxns = await fetchServiceTxns({
    reservationId: reservation.id,
    'service._id': serviceId,
    noCancel: true,
  })

  const existServiceTxn = serviceTxns[0]

  if (existServiceTxn)
    dispatch({
      type: types.LOAD_CHECKIN_SERVICETXN,
      serviceTxn: existServiceTxn,
    })
  else
    dispatch({
      type: types.LOAD_CHECKIN_WITH_AGENT,
      reservation,
    })

  if (onCompleted) onCompleted(existServiceTxn)
}

export const loadServiceTxn = serviceTxn => ({
  type: types.LOAD_CHECKIN_SERVICETXN,
  serviceTxn,
})

export const selectCheckInType = checkInInfo => ({
  type: types.SELECT_CHECKIN_TYPE,
  checkInInfo,
})

export const submitCheckIn = () => ({
  type: types.SUBMIT_CHECKIN,
})

export const fetchCheckIn = id => ({
  type: types.FETCH_CHECKIN,
  meta: { fetch_id: 'checkin', type: 'single' },
  $payload: {
    url: `/api/serviceTxns/${id}`,
  },
})

export const clearCheckIn = () => ({
  type: types.CLEAR_CHECKIN,
})

export const updateFilterReservationText = text => ({
  type: types.UPDATE_FILTER_RESERVATION_TEXT,
  text,
})

export const updateServiceInfo = (
  serviceName,
  serviceClass,
  defaultDuration,
) => async (dispatch, getState) => {
  const serviceTxn = getState().checkin.serviceTxn
  let classToServiceMapping
  switch (serviceTxn.checkInType) {
    case 'airline':
      if (
        serviceTxn.airlineCheckInInfo &&
        serviceTxn.airlineCheckInInfo.airline &&
        serviceTxn.airlineCheckInInfo.airline.id &&
        !serviceTxn.airlineCheckInInfo.airline.classToServiceMapping
      ) {
        const airline = await fetchAirlineById(
          serviceTxn.airlineCheckInInfo.airline.id,
        )
        classToServiceMapping = airline.classToServiceMapping
      }
      break
    case 'walkin':
      if (
        serviceTxn.cardCheckInInfo &&
        serviceTxn.cardCheckInInfo.type === 'card' &&
        serviceTxn.cardCheckInInfo.card &&
        serviceTxn.cardCheckInInfo.card.id &&
        !serviceTxn.cardCheckInInfo.card.classToServiceMapping
      ) {
        const card = await fetchCardById(serviceTxn.cardCheckInInfo.card.id)
        classToServiceMapping = card.classToServiceMapping
      }
      break
    default:
      break
  }

  dispatch(upateProductPrice())

  dispatch({
    type: types.UPDATE_CHECKIN_SERVICETXN_SERVICEINFO,
    serviceName,
    serviceClass,
    defaultDuration,
    classToServiceMapping,
  })
}

export const useVoucherPrivilege = duration => ({
  type: types.USE_VOUCHER_PRIVILEGE,
  duration,
})

export const useComplimentaryPrivilege = duration => ({
  type: types.USE_COMPLIMENTARY_PRIVILEGE,
  duration,
})

export const useGuestPrivilege = serviceTxn => ({
  type: types.USE_GUEST_PRIVILEGE,
  serviceTxn,
})

export const clearExtraPrivilege = () => ({
  type: types.CLEAR_EXTRA_PRIVILEGE,
})

export const upateProductPrice = () => ({
  type: types.UPDATE_PRODUCT_PRICE,
})

export { runCodeScanInfo } from './checkin.codeScan.js'
