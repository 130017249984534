import React from 'react'
import ServiceTxnTableContainer from './ServiceTxnTableContainer'
import { connect } from 'react-redux'
import { fetchCheckedoutServiceTxns } from '../../actions/remote_api'
import storage from '../../storage'
import { transformServiceTxns } from '../../utils/serviceTxn'
import { toastr } from 'react-redux-toastr'

class CheckedoutServiceTxnTableContainer extends React.Component {

  fetchCheckedoutServiceTxns = (pagination) => {
    toastr.info('Loading')
    this.props.fetchCheckedoutServiceTxns({
      ...pagination,
      checkedout: true,
      operationDaily: true,
      stationId: storage.info().stationId,
    })
  }

  settings = {
    selectRow: undefined
  }

  renderButtonGroup = () => {
    return (
      <div></div>
    )
  }

  render() {
    return (

      <ServiceTxnTableContainer
        {...this.props}
        instances={this.props.serviceTxns}
        fetchInstances={this.fetchCheckedoutServiceTxns}
        actionable={false}
        btnGroup={this.renderButtonGroup}
        settings={this.settings}
        onDataChange={this.handleDataChange}
        totalCount={this.props.totalCount} />

    )
  }
}

export default connect(
  ({ fetch }) => ({
    serviceTxns: fetch.checkedoutServiceTxn ?
      transformServiceTxns(fetch.checkedoutServiceTxn.list) : undefined,
    totalCount: fetch.checkedoutServiceTxn ?
      fetch.checkedoutServiceTxn.totalCount : undefined,
  }),
  { fetchCheckedoutServiceTxns }
)(CheckedoutServiceTxnTableContainer)
