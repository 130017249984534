import React from 'react'
import { SplitView, LeftPanel, RightPanel } from '../../components/SplitView'
import WorkSummaryListViewContainer from '../../components/WorkSummaryListView'
import WorkSummaryDetailView from './WorkSummaryDetailView'

export default class WorkSummarySplitView extends React.Component {

  state = {
    activeWorkSummary: undefined,
  }

  handleWorkSummaryClick = workSummary => {
    this.setState({
      activeWorkSummary: workSummary
    })
  }

  render() {
    return (
      <SplitView>
        <div className="summary-report">
          <LeftPanel>
            <WorkSummaryListViewContainer
              onWorkSummaryClick={this.handleWorkSummaryClick}
              onBack={this.props.onBack} />
          </LeftPanel>
          <RightPanel>
            <WorkSummaryDetailView workSummary={this.state.activeWorkSummary} />
          </RightPanel>
        </div>
      </SplitView>
    )
  }
}