import React from 'react'
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
} from 'react-bootstrap'
import { isEmpty } from 'lodash'
import styled from 'styled-components'

export default ({
  isShow,
  onHide,
  jobAssignment,
  onSubmit,
}) => {
  if ([jobAssignment].some(isEmpty))
    return null

  const { staff, service } = jobAssignment

  return (
    <Modal
      show={isShow}
      onHide={onHide}
      bsSize="md">
      <ModalHeader closeButton>
        <ModalTitle>
          ACCEPT JOB CONFIRM
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ModalContent>
          Is <strong>"<u>{staff.firstName} {staff.lastName}</u>"</strong> intentionally to accept job assignment of <strong>"<u>{service.displayName}</u>"</strong> ?
        </ModalContent>
      </ModalBody>
      <ModalFooter>
        <Button
          bsStyle="default"
          onClick={onHide} >
          Cancel
          </Button>
        <Button
          bsStyle="primary"
          onClick={onSubmit} >
          Confirm
          </Button>
      </ModalFooter>
    </Modal >
  )
}

const ModalContent = styled.div`
  font-size: 20px;
`