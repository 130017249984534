import Select from 'react-select'
import styled from 'styled-components'

const TextAreaSelect = styled(Select)`
  .Select-control {
    height: 80px !important;

    .Select-clear-zone {
      vertical-align: top;
      line-height: 34px;
    }

    .Select-arrow-zone {
      padding-top: 7.5px;
      vertical-align: top;
    }
    .Select-value-label {
      white-space: normal;
    }
    .Select-value {
      overflow-y: auto;
      line-height: 24px;
      padding-top: 5px;
    }
  }
`

export default TextAreaSelect
