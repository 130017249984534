import moment from 'moment'
import traverse from 'traverse'

export const parseFieldToMomentIfNeeded = data =>
  // eslint-disable-next-line
  traverse(data).map(function(item) {
    if (
      item &&
      this.key &&
      (this.key.endsWith('Date') ||
        this.key.endsWith('DateTime') ||
        this.key.endsWith('Time'))
    ) {
      this.update(moment(item), true)
    }
  })
