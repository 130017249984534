import React from 'react'
import { connect } from 'react-redux'
import { clickLogout } from '../../actions/auth'

const LogoutContainer = ({ clickLogout, children, onLogout }) => (
  React.cloneElement(children, {
    onClick: () => {
      clickLogout()
      if (onLogout)
        onLogout()
    }
  })
)

export default connect(null, { clickLogout })(LogoutContainer)