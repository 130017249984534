import React from 'react'
import GuestPrivilegeModal from './GuestPrivilegeModal'
import { fetchRecentlyServiceTxns } from '../../actions/remote_api'
import { connect } from 'react-redux'
import { info } from '../../storage'
import { useGuestPrivilege } from '../../actions/checkin'

class GuestPrivilegeModalContainer extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (!this.props.isShow && nextProps.isShow) {
      const { serviceId } = info()
      this.props.fetchRecentlyServiceTxns({ serviceId })
    }
  }

  handleServiceTxnClick = serviceTxn => {
    this.props.useGuestPrivilege(serviceTxn)
    this.props.onHide()
  }

  render() {
    return (
      <GuestPrivilegeModal
        isShow={this.props.isShow}
        onHide={this.props.onHide}
        recentlyServiceTxns={this.props.recentlyServiceTxns}
        onServiceTxnClick={this.handleServiceTxnClick} />
    )
  }
}

export default connect(
  ({ fetch: { recentlyServiceTxn } }) => ({
    recentlyServiceTxns: recentlyServiceTxn && recentlyServiceTxn.list ?
      recentlyServiceTxn.list :
      undefined,
  }),
  { fetchRecentlyServiceTxns, useGuestPrivilege }
)(GuestPrivilegeModalContainer)