import {
  selectReservation,
  selectCheckInType,
  updateCheckInServiceTxnField,
  loadServiceTxn,
  updateServiceInfo,
} from './checkin'
import { fetchAirlines, fetchReservations, fetchServiceTxns } from '../api'
import { forEach } from 'lodash'
import { escape } from '../utils/regex'

export const runCodeScanInfo = (codeScanInfo, kioskInfo, onError) => async (
  dispatch,
  getState,
) => {
  switch (codeScanInfo.context) {
    case 'airline_ticket':
      await runCodeScanInfoTypeAirline(codeScanInfo, kioskInfo, onError)(
        dispatch,
        getState,
      )
      break
    case 'coral_voucher':
      await runCodeScanInfoTypeVoucher(codeScanInfo, kioskInfo, onError)(
        dispatch,
      )
      break
    case 'text':
    default:
      if (onError)
        onError({
          code: 'context.notSupported',
          message: "codeScanInfo's context is not supported",
        })
      break
  }
}

const runCodeScanInfoTypeAirline = ({ info }, kioskInfo, onError) => async (
  dispatch,
  getState,
) => {
  const state = getState().checkin
  if (state && state.serviceTxn.checkInType) {
    updateCheckInServiceTxnWithCodeScanInfo(info, kioskInfo)(dispatch, getState)
    return
  }

  if (info.seatNumber && info.fullFlightNumber) {
    const serviceTxn = await getServiceTxnBySeatAndFlightNumber(
      info.seatNumber,
      info.fullFlightNumber,
      kioskInfo.serviceId,
    )

    if (serviceTxn) {
      dispatch(loadServiceTxn(serviceTxn))
      return
    }
  }

  if (!info.airlineCode) {
    if (onError)
      onError({
        code: 'airlineCode.notFound',
        message: 'airlineCode is not found in info',
      })
    return
  }

  const airline = await getAirlineByCode(info.airlineCode)
  if (!airline) {
    if (onError)
      onError({
        code: 'airline.notFound',
        airlineCode: info.airlineCode,
        message: `Airline is not found for given ${info.airlineCode}`,
      })
    return
  }

  dispatch(
    selectCheckInType({
      checkInType: 'airline',
      airlineCheckInInfo: { airline },
    }),
  )
  updateCheckInServiceTxnWithCodeScanInfo(info, kioskInfo)(dispatch, getState)
}

// map of { codeScanKey: serviceTxnKeyPath }
const codeScanInfoToServiceTxnKeyPathMap = {
  fullFlightNumber: 'flightNumber',
  seatNumber: 'seatNumber',
  travelClass: 'travelClass',
  checkInSequenceNumber: 'reference1',
}

const updateCheckInServiceTxnWithCodeScanInfo = (info, kioskInfo) => (
  dispatch,
  getState,
) => {
  forEach(codeScanInfoToServiceTxnKeyPathMap, (keyPath, key) =>
    dispatch(updateCheckInServiceTxnField(keyPath, info[key])),
  )
  const state = getState().checkin
  if (!state) return
  if (!state.serviceTxn.customerFirstName)
    dispatch(updateCheckInServiceTxnField('customerFirstName', info.firstName || ''))
  if (!state.serviceTxn.customerLastName)
    dispatch(updateCheckInServiceTxnField('customerLastName', info.lastName || ''))

  dispatch(updateCheckInServiceTxnField('serviceInfo.guestPaxAdult', 1))

  dispatch(
    updateServiceInfo(
      kioskInfo.serviceName,
      kioskInfo.serviceClass,
      kioskInfo.defaultDurations,
    ),
  )
}

const runCodeScanInfoTypeVoucher = (
  { info },
  kioskInfo,
  onError,
) => async dispatch => {
  if (!info.confirmationNumber) {
    if (onError)
      onError({
        code: 'confirmation.notFound',
        message: 'ConfirmationNumber is not found in info',
      })
    return
  }

  const reservation = await getReservationByCode(
    info.confirmationNumber,
    kioskInfo.serviceId,
  )
  if (!reservation) {
    if (onError)
      onError({
        code: 'reservation.notFound',
        confirmationNumber: info.confirmationNumber,
        message: `Reservation is not found for given ${
          info.confirmationNumber
        }`,
      })
    return
  }

  selectReservation(reservation, kioskInfo.serviceId)(dispatch)
}

const getAirlineByCode = code =>
  fetchAirlines({
    code,
  }).then(airline => airline[0])

const getReservationByCode = (confirmationNumber, serviceId) =>
  fetchReservations({
    confirmationNumber_like: confirmationNumber ? escape(confirmationNumber) : undefined,
    operationDaily: true,
    serviceId,
  }).then(reservation => reservation[0])

const getServiceTxnBySeatAndFlightNumber = (
  seatNumber,
  flightNumber,
  serviceId,
) =>
  fetchServiceTxns({
    seatNumber: seatNumber,
    flightNumber: flightNumber,
    operationDaily: true,
    'service._id': serviceId,
  }).then(serviceTxn => serviceTxn[0])
