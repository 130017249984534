import { isEmpty } from 'lodash'

export const saveInfo = info => {
  if (info.serviceId)
    localStorage.setItem('serviceId', info.serviceId)
  if (info.serviceName)
    localStorage.setItem('serviceName', info.serviceName)
  if (info.serviceClass)
    localStorage.setItem('serviceClass', info.serviceClass)
  if (info.serviceDisplayName)
    localStorage.setItem('serviceDisplayName', info.serviceDisplayName)
  if (info.stationId)
    localStorage.setItem('stationId', info.stationId)
  if (info.stationName)
    localStorage.setItem('stationName', info.stationName)
}

export const info = () => {
  return {
    serviceId: localStorage.getItem('serviceId'),
    serviceName: localStorage.getItem('serviceName'),
    serviceClass: localStorage.getItem('serviceClass'),
    serviceDisplayName: localStorage.getItem('serviceDisplayName'),
    stationId: localStorage.getItem('stationId'),
    stationName: localStorage.getItem('stationName'),
  }
}

export const hasInfo = () => {
  const _info = info()
  if (!_info) return false

  return (
    !isEmpty(_info.serviceId) &&
    !isEmpty(_info.serviceName) &&
    !isEmpty(_info.serviceClass) &&
    !isEmpty(_info.serviceDisplayName) &&
    !isEmpty(_info.stationId) &&
    !isEmpty(_info.stationName)
  )
}

export const clearInfo = () => {
  localStorage.removeItem('serviceId')
  localStorage.removeItem('serviceName')
  localStorage.removeItem('serviceClass')
  localStorage.removeItem('serviceDisplayName')
  localStorage.removeItem('stationId')
  localStorage.removeItem('stationName')
}

export default {
  saveInfo,
  info,
  hasInfo,
  clearInfo,
}