import React from 'react'
import { I18n } from 'react-redux-i18n'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Icon from 'react-icons-kit'
import { checkmark, iosPaper } from 'react-icons-kit/ionicons'
import _ from 'lodash'
import { priceFormat } from '../../utils/currency'
import { countPax } from '../../utils/serviceTxn'

const CheckInTypeResult = props => {
  if (!props.checkInType)
    return (
      <div className="center-img">
        <div className="title-kiosk " />
      </div>
    )
  return renderCheckInResult(props)
}

export default connect(({ checkin }) => ({ ...checkin.serviceTxn }))(
  CheckInTypeResult,
)

const renderCheckInResult = ({
  checkInType,
  airlineCheckInInfo,
  agentCheckInInfo,
  walkinCheckInInfo,
  serviceInfo,
}) => {
  switch (checkInType) {
    case 'airline':
      return (
        <AirlineCheckInResult
          info={airlineCheckInInfo}
          serviceInfo={serviceInfo}
        />
      )
    case 'agent':
      return (
        <AgentCheckInResult info={agentCheckInInfo} serviceInfo={serviceInfo} />
      )
    case 'walkin':
      return (
        <WalkinCheckInResult
          info={walkinCheckInInfo}
          serviceInfo={serviceInfo}
        />
      )
    default:
      return null
  }
}

const AirlineCheckInResult = ({ info: { airline }, serviceInfo }) => (
  <ProductPriceIcon serviceInfo={serviceInfo}>
    {productPriceIcon => (
      <div className="center-img">
        {renderDuration(serviceInfo)}
        {productPriceIcon}
        <div className="title-kiosk">
          <AirlineLogoCon>
            {airline.logo && (
              <AirlineLogo
                title={airline.name}
                alt={airline.name}
                src={airline.logo}
              />
            )}
            {!airline.logo && <NoLogo>{airline.code}</NoLogo>}
          </AirlineLogoCon>
          <AirlineTitle>{airline.name} ({airline.code})</AirlineTitle>
        </div>
      </div>
    )}
  </ProductPriceIcon>
)

const TopRightLogo = styled.div`
  margin-left: 87%;
  margin-top: -4%;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  line-height: 50px;
  text-align: center;
  background: #000;
  padding: 5px;
`

const NoLogo = styled.div`
  background: #72421a;
  padding: 5px;
  width: 46px;
  height: 46px;
  line-height: 36px;
  color: white;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  margin: auto;
  border-radius: 28px;
`
const AirlineLogo = styled.img`
  max-width: 130px;
  max-height: 60px;
  margin: auto;
`
const AirlineTitle = styled.div`
  font-size: 14px;
  flex-grow: 1;
  height: 25%;
  align-self: auto;
  margin: auto;
`
const AirlineLogoCon = styled.div`
  width: 100%;
  height: 75%;
  text-align: center;
  display: flex;
`

const AgentCheckInResult = ({ info: { agent }, serviceInfo }) => (
  <div className="center-img">
    {renderDuration(serviceInfo)}
    <div className="title-kiosk ">{agent.name}</div>
  </div>
)

const WalkinCheckInResult = ({ info: { type, card }, serviceInfo }) => (
  <ProductPriceIcon serviceInfo={serviceInfo}>
    {productPriceIcon => (
      <div className="center-img">
        {renderDuration(serviceInfo)}
        {productPriceIcon}
        <div className="title-kiosk ">
          {renderWalkinCheckInResult(type, card, serviceInfo)}
        </div>
      </div>
    )}
  </ProductPriceIcon>
)

const ProductPriceIcon = ({ serviceInfo, children }) =>
  children(
    _.get(serviceInfo, 'productPrice.pricingProduct') ? (
      <TopRightLogo>
        <Icon icon={iosPaper} size={24} />
      </TopRightLogo>
    ) : null,
  )

const renderWalkinCheckInResult = (type, card, serviceInfo) => {
  if (type === 'card')
    return (
      <div>
        <div>
          {card.name} {I18n.t(`checkInScene.field.${type}`)}
          <h4>{card ? card.privilegeClass : ''}</h4>
        </div>
      </div>
    )
  return (
    <div>
      {I18n.t(`checkInScene.field.${type}`)}
      {renderWalkinPrice(type, serviceInfo)}
    </div>
  )
}

const renderWalkinPrice = (type, serviceInfo) => {
  switch (type) {
    case 'cash':
    case 'creditCard':
      const productPrice = _.get(serviceInfo, 'productPrice')
      if (_.isNil(productPrice)) return null
      return renderProductPrice(productPrice, serviceInfo)
    default:
      return null
  }
}

const renderProductPrice = (productPrice, serviceInfo) => {
  const countPaxAmount = countPax(serviceInfo)
  return (
    <h4>
      {priceFormat(productPrice.price * countPaxAmount)} (
      {priceFormat(productPrice.price)} x {countPaxAmount})
    </h4>
  )
}

const renderDuration = serviceInfo => {
  if (!serviceInfo || serviceInfo.duration === undefined) {
    return null
  }
  if (serviceInfo.duration > 0)
    return <div className="hour-label">{serviceInfo.duration} hr.</div>
  else
    return (
      <div className="hour-label">
        <DurationPassIcon />
      </div>
    )
}

const DurationPassIcon = styled(Icon).attrs({
  icon: checkmark,
  size: 24,
})`
  margin-top: 13px;
`
